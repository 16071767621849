import { keyframes, css } from 'styled-components/macro';

const fadeInDownSmallKeyFrame = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeInDownSmall = css`
  animation-name: ${fadeInDownSmallKeyFrame};
`;

export default {
  fadeInDownSmall,
};

import { css } from 'styled-components/macro';

const sizes = {
  laptop: 1460,
  tablet: 1300,
  phone: 1030,
  sm: 700,
  smm: 580,
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

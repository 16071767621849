const colors = {
  white: '#fff',
  black: '#000',
  brightTurquoise: '#00C6FB',
  lochmara: '#007FC4',
  lochamaraDark: '#0078bf',
  blue: '#3179b0',
};

export { colors };

import React from 'react';
import styled from 'styled-components/macro';
import { colors, media } from 'styles';

const Footer = () => (
  <Wrapper>
    <Copyright>© 2006—2019 ПАО Газпром нефть. Все права защищены.</Copyright>
  </Wrapper>
);

const Copyright = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${colors.white};
  opacity: 0.8;
  font-weight: 500;
  padding-left: env(safe-area-inset-left);
  ${media.phone`
    font-size: 8px;
    line-height: 18px;
  `}
`;

const Wrapper = styled.div`
  padding: 0 79px 36px 80px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  ${media.phone`
    padding: 0 30px 14px 30px;
  `}
`;

export default Footer;

import { createGlobalStyle } from 'styled-components/macro';
import reset from 'styled-reset';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
    outline: none;
  }

  html,
  body,
  #react-view {
    margin: 0;
    padding: 0;
    width: 100%;
    min-width: 320px;
    background-color: transparent;
  }

  body {
    font-family: 'DINPro', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-smoothing: antialiased; /* stylelint-disable-line property-no-unknown */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-size: 10px;
    overscroll-behavior: none;
    overflow-y: scroll;
    overflow-x: hidden;
    line-height: normal
  }
  
  a {
    text-decoration: none;
  }
  .popup-overlay{
    -webkit-overflow-scrolling: touch;
  }

  /* возможно ниже написанный стили не понадобяться */
  
  .route-enter-active {
    position: relative;
    height: initial;
    min-height: 100vh;
    pointer-events: none;
  }

 .route-exit {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
  }
`;

import React from 'react';

const GazProm = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M20.745 12.513H24c-.031.456-.028.91-.097 1.352-.708 4.485-3.229 7.643-7.624 9.35-4.642 1.805-10.225.408-13.434-3.286C.926 17.721-.028 15.181.001 12.31.046 7.76 3.063 3.536 7.483 1.865c3.382-1.279 6.714-1.128 9.967.43.243.117.364.084.538-.1.648-.684 1.316-1.352 1.977-2.026.044-.044.094-.083.19-.169l.693 7.355-8.16.264 2.7-2.772c-2.78-1.25-6.751-.893-9.513 1.695-2.717 2.546-3.393 6.558-1.627 9.82 1.761 3.255 5.568 5.038 9.371 4.355 3.784-.68 7.028-3.825 7.126-8.204"
        mask="url(#b)"
      />
    </g>
  </svg>
);

export default GazProm;

import React from 'react';

const OdnoClassniki = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <g fill="none" fillRule="evenodd">
      <rect width="36" height="35.027" fill="#FF9800" rx="17.514" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M17.564 17.8c2.967 0 5.371-2.418 5.371-5.4S20.531 7 17.565 7c-2.967 0-5.371 2.418-5.371 5.4.003 2.98 2.406 5.396 5.37 5.4zm0-8.1a2.693 2.693 0 0 1 2.686 2.7c0 1.491-1.202 2.7-2.685 2.7a2.693 2.693 0 0 1-2.686-2.7c0-1.491 1.202-2.7 2.686-2.7z"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M23.137 20.991c.618-.375.991-1.012.992-1.694.008-.566-.338-1.087-.89-1.335a1.78 1.78 0 0 0-1.75.151c-2.339 1.572-5.51 1.572-7.847 0a1.79 1.79 0 0 0-1.75-.151c-.553.248-.9.768-.892 1.334.001.683.374 1.319.992 1.695a10.5 10.5 0 0 0 2.564 1.13c.154.043.313.084.477.122l-2.645 2.384a1.526 1.526 0 0 0-.037 2.282c.673.64 1.781.655 2.475.035l.043-.04 2.696-2.572 2.7 2.577c.673.64 1.781.656 2.476.036a1.526 1.526 0 0 0-.004-2.321l-2.64-2.382a10.46 10.46 0 0 0 3.04-1.25z"
      />
    </g>
  </svg>
);

export default OdnoClassniki;

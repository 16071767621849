import { keyframes, css } from 'styled-components/macro';

const fadeOutKeyFrame = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
`;

const fadeOut = css`
  animation-name: ${fadeOutKeyFrame};
`;

export default {
  fadeOut,
};

import { keyframes, css } from 'styled-components/macro';

const fadeInUpSmallKeyFrame = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeInUpSmall = css`
  animation-name: ${fadeInUpSmallKeyFrame};
`;

export default {
  fadeInUpSmall,
};

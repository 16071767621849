import { keyframes, css } from 'styled-components/macro';

const slideInDownKeyFrame = keyframes`
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideInDown = css`
  animation-name: ${slideInDownKeyFrame};
`;

export default {
  slideInDown,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Switch, Route, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import isVisible from './utils/isVisible';
import { timeoutsShape } from './utils/propTypes';

import MainLayout from '../Layouts/MainLayout';

const getKey = pathname => {
  if (pathname.includes('slogan')) {
    return 'labs';
  }

  return pathname;
};

class ReactTransitionGroup extends PureComponent {
  render() {
    const { routes, timeout, location } = this.props;

    const key = getKey(location.pathname);

    return (
      <TransitionGroup component={null}>
        <CSSTransition key={key} classNames="route" timeout={timeout}>
          {status => (
            <Switch location={location} key={key}>
              {routes.map(route => (
                <Route
                  key={route.path}
                  exact={route.exact}
                  path={route.path}
                  render={props => {
                    const PageContent = route.component;

                    return (
                      <MainLayout>
                        <PageContent
                          {...props}
                          transitionStatus={status}
                          isVisiblePage={isVisible(status)}
                        />
                      </MainLayout>
                    );
                  }}
                />
              ))}
            </Switch>
          )}
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

ReactTransitionGroup.propTypes = {
  location: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  timeout: timeoutsShape.isRequired,
};

export default withRouter(ReactTransitionGroup);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import delay from 'lodash/delay';
import { ConnectedRouter } from 'connected-react-router';

import { actions, selectors } from 'redux/ducks/common';
import { actions as mainActions } from 'redux/ducks/main';

import chekcSideRation from 'utils/chekcSideRation';
import { RouterTransitionGroup } from 'components/ReactTransitionGroup';
import { PAGE_TRANSITION } from 'constants/index';
// THEMES
import { GlobalStyle } from 'styles';

// ROUTES
import routes from './routes';

const MAX_DESKTOP_WIDTH = 1024;

// EXPORTED APP
class App extends PureComponent {
  static propTypes = {
    isNeedFlipScreen: PropTypes.bool.isRequired,
    changeFlipScreen: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    changeScreenSizes: PropTypes.func.isRequired,
  };

  componentDidMount() {
    window.addEventListener('resize', this.delayedHandleResize);
    this.handleResize();
    delay(this.handleResize, 500);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  delayedHandleResize = () => delay(this.handleResize, 100);

  handleResize = () => {
    const { innerWidth, innerHeight } = window;
    const {
      changeScreenSizes,
      isNeedFlipScreen,
      changeFlipScreen,
    } = this.props;

    // ниже для модального окна

    if (
      innerWidth < MAX_DESKTOP_WIDTH &&
      innerHeight > innerWidth &&
      !isNeedFlipScreen
    ) {
      changeFlipScreen(true);
    } else if (isNeedFlipScreen) {
      if (innerWidth > MAX_DESKTOP_WIDTH || innerHeight < innerWidth) {
        changeFlipScreen(false);
      }
    }

    const { isAlighCenterForWidth, width, height } = chekcSideRation(
      innerWidth,
      innerHeight,
    );

    if (isAlighCenterForWidth) {
      changeScreenSizes({
        isAlighCenterForWidth,
        correctWidth: width,
        correctHeight: innerHeight,
        windowWidth: innerWidth,
        windowHeight: innerHeight,
      });
    } else {
      changeScreenSizes({
        isAlighCenterForWidth,
        correctWidth: innerWidth,
        correctHeight: height,
        windowHeight: innerHeight,
        windowWidth: innerWidth,
      });
    }
  };

  render() {
    const { history } = this.props;

    return (
      <ConnectedRouter history={history}>
        <React.Fragment>
          <RouterTransitionGroup routes={routes} timeout={PAGE_TRANSITION} />
          <GlobalStyle />
        </React.Fragment>
      </ConnectedRouter>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changeFlipScreen: isShow => dispatch(actions.changeFlipScreen(isShow)),
  changeScreenSizes: sizes => dispatch(mainActions.changeScreenSizes(sizes)),
});

const mapStateToProps = state => ({
  isNeedFlipScreen: selectors.getFlipScreen(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import styled, { keyframes } from 'styled-components/macro';

const AutoTicker = ({ className, children }) => (
  <Root className={className}>
    <Wrapper>
      {children}
      {React.cloneElement(children, {
        key: 'extarblock',
      })}
    </Wrapper>
  </Root>
);

AutoTicker.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

const scroll = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0)
  }
`;

const Root = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: flex;
`;

const Wrapper = styled.div`
  justify-content: flex-start;
  animation: ${scroll} 50s infinite linear;
  display: flex;
  will-change: transform;
`;

export default AutoTicker;

import React from 'react';
import styled from 'styled-components/macro';

import AutoTicker from 'components/AutoTicker';

import { media } from 'styles';
import { data } from './data';

const CarouselLogos = () => (
  <Wrapper>
    <AutoTicker>
      <List>
        {data.map(item => (
          <Image key={item.id} src={item.img} alt={item.title} />
        ))}
      </List>
    </AutoTicker>
  </Wrapper>
);

const Wrapper = styled.div`
  max-width: 100vw;
  width: 100%;
  margin-bottom: 165px;

  ${media.laptop`
      margin-bottom: 24px;
  `}

  ${media.tablet`
    margin-bottom: 30px;
  `}
`;

const List = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  padding: 0 35px;
`;

export default CarouselLogos;

import { createActions, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

const WIDTH_SQ = 1920;
const HEIGHT_SQ = 1080;

const initialState = {
  animationIsEnd: false,
  screenSizes: {
    windowWidth: WIDTH_SQ,
    windowHeight: HEIGHT_SQ,
    correctWidth: WIDTH_SQ,
    correctHeight: HEIGHT_SQ,
    isAlighCenterForWidth: false,
  },
  isFullAnimation: true,
};

const actions = createActions(
  'CHANGE_ANIMATION_IS_END',
  'CHANGE_SCREEN_SIZES',
  'CHANGE_FULL_ANIMATION',
);

const reducer = handleActions(
  {
    [actions.changeAnimationIsEnd]: (state, { payload }) => ({
      ...state,
      animationIsEnd: payload,
    }),
    [actions.changeScreenSizes]: (state, { payload }) => ({
      ...state,
      screenSizes: payload,
    }),
    [actions.changeFullAnimation]: (state, { payload }) => ({
      ...state,
      isFullAnimation: payload,
    }),
  },
  initialState,
);

const getState = state => state.main;

const selectors = {
  getAnimationEnd: createSelector(
    [getState],
    s => s.animationIsEnd,
  ),
  getScreenSizes: createSelector(
    [getState],
    s => s.screenSizes,
  ),
  getFullAnimation: createSelector(
    [getState],
    s => s.isFullAnimation,
  ),
};

export { initialState as state, reducer, actions, selectors };

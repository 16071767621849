import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { colors, media } from 'styles';

const Content = ({ handleBtnClick }) => (
  <Wrapper>
    <Title>«Набор будущего чемпиона»:</Title>
    <SubTitle>как стать звездой хоккея</SubTitle>
    <Description>
      С 22 по 26 апреля в Сочи пройдет крупнейший в мире международный
      некоммерческий детский хоккейный турнир «Кубок Газпром нефти»: 31 команда,
      10 стран, игры на олимпийских аренах, прямые трансляции и десятки тысяч
      болельщиков. Лайф пообщался со спортсменами и их наставниками со всего
      мира, чтобы узнать, как растут будущие чемпионы.
    </Description>
    <StyledButton onClick={handleBtnClick}>Открывай!</StyledButton>
  </Wrapper>
);

Content.propTypes = {
  handleBtnClick: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  display: block;
  color: ${colors.white};
  text-align: center;
  z-index: 1;
  position: relative;
`;

const SubTitle = styled.h2`
  font-size: 56px;
  line-height: 72px;
  font-weight: 800;
  margin-bottom: 21px;
  
  ${media.phone`
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 10px;
  `}

  ${media.sm`
    font-size: 28px;
    margin-bottom: 5px;
  `}

  ${media.smm`
    font-size: 26px;
    margin-bottom: 10px;
  `}
`;

const Title = styled.h1`
  font-size: 72px;
  line-height: 72px;
  font-weight: 800;
  
  ${media.phone`
    font-size: 36px;
    line-height: 0.9;
  `}

  ${media.sm`
    font-size: 34px;
  `}

  ${media.smm`
    font-size: 30px;
  `}
`;

const Description = styled.p`
  opacity: 0.8;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  max-width: 995px;
  margin: 0 auto;
  margin-bottom: 21px;
  
  ${media.phone`
    font-size: 14px;
    line-height: 1.37;
    margin-bottom: 10px;
  `}

  ${media.sm`
    font-size: 13px;
  `}

  ${media.smm`
    font-size: 12px;
  `}
`;

const StyledButton = styled.div`
  width: 380px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-image: linear-gradient(105deg, #00c6fb, #007fc4 73%, #0078bf);
  border-radius: 48px;
  box-shadow: 0 8px 24px 0 rgba(0, 127, 196, 0.7);
  font-size: 30px;
  font-weight: 700;
  color: ${colors.white};
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: 0 4px 14px 0 rgba(0, 127, 196, 0.3);
  }

  ${media.phone`
    width: 254px;
    height: 55px;
    font-size: 20px;
    letter-spacing: 1.3px;
  `}
`;

export default Content;

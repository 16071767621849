import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectors as commonSelectors } from 'redux/ducks/common';

import Welcome from 'components/Welcome';

import Common from './Common';

const MainLayout = ({ children, match, isNeedFlipScreen }) => (
  <div>
    {children}
    {isNeedFlipScreen && <Welcome />}
    <Common pathname={match.path} />
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
  match: PropTypes.shape().isRequired,
  isNeedFlipScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isNeedFlipScreen: commonSelectors.getFlipScreen(state),
});

export default connect(mapStateToProps)(withRouter(MainLayout));

import React from 'react';

const Link = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M3.143 0v3.692H0L5.5 8 11 3.692H7.857V0H3.143zM0 11h11V9H0v2z"
    />
  </svg>
);

export default Link;

import React from 'react';

const FaceBook = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
  >
    <g fill="none" fillRule="evenodd">
      <rect width="36" height="35.027" fill="#3B5998" rx="17.514" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M22.623 8.004L20.225 8c-2.695 0-4.436 1.739-4.436 4.43v2.042h-2.412a.372.372 0 0 0-.377.367v2.96c0 .203.169.367.377.367h2.412v7.467c0 .203.169.367.377.367h3.146a.372.372 0 0 0 .378-.367v-7.467h2.82a.372.372 0 0 0 .376-.367l.002-2.96c0-.097-.04-.19-.11-.26a.383.383 0 0 0-.268-.107h-2.82v-1.731c0-.832.203-1.255 1.317-1.255h1.616a.372.372 0 0 0 .377-.367V8.37a.372.372 0 0 0-.377-.367z"
      />
    </g>
  </svg>
);

export default FaceBook;

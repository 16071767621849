import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { media, hoverSupport } from 'styles';

import Arrow from 'assets/images/slide-arrow.svg';

const SliderArrow = ({ to, onClick }) => {
  if (to === 'prev') {
    return <PrevArrow onClick={onClick} />;
  }
  return <NextArrow onClick={onClick} />;
};

SliderArrow.propTypes = {
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const NextArrow = styled.div`
  position: absolute;
  width: 60px;
  height: 100%;
  cursor: pointer;
  color: transparent;
  border: none;
  right: -65px;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-top: -26px;
  background-image: url("${Arrow}");
  transform: rotate(180deg);
  background-size: 27px;
  background-repeat: no-repeat;
  background-position: 10px center;
  transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
  &:hover {
    opacity: 0.5;
  }
  ${media.phone`
    right: -60px;
    margin-top: -14px;
  `}
`;

const PrevArrow = styled.div`
  position: absolute;
  width: 60px;
  height: 100%;
  cursor: pointer;
  color: transparent;
  border: none;
  left: -65px;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-top: -26px;
  background-image: url("${Arrow}");
  background-size: 27px;
  background-repeat: no-repeat;
  background-position: 10px center;
  transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
 
  ${hoverSupport`
    opacity: 0.5;
  `}
  
  ${media.phone`
    left: -60px;
    margin-top: -14px;
  `}
`;

export default SliderArrow;

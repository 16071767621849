import React from 'react';
import FileIcon from 'components/SvgIcons/Link';
import styled from 'styled-components/macro';
import { colors, media, hoverSupport } from 'styles';

const MainMask = () => (
  <React.Fragment>
    <Title>
      Поддержи будущих
      <br />
      чемпионов!
    </Title>
    <Description>
      Создай свою кричалку с помощью нашего генератора и опубликуй её в соцсетях
      с пожеланиями любимой команде и хештегом #кубокгазпромнефти. Мы выберем
      самые яркие посты и подарим их авторам «наборы чемпионов»: футболки, кепки
      и рюкзаки с символикой «Кубка Газпром нефти».
    </Description>
    <FileLink href="/rules.pdf" target="_blank">
      <FileIconWrap>
        <FileIcon />
      </FileIconWrap>
      <FileLinkText>Положение конкурса в PDF</FileLinkText>
    </FileLink>
  </React.Fragment>
);

const Title = styled.h1`
  font-size: 78px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 78px;
  letter-spacing: normal;
  margin-bottom: 34px;
  color: ${colors.white};
  text-transform: uppercase;
  ${media.laptop`
    font-size: 56px;
    line-height: 56px;
    margin-bottom: 24px;
  `}
  
  ${media.tablet`
    font-size: 44px;
    line-height: 44px;
    margin-bottom: 15px;
  `}
  
  ${media.phone`
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 12px;
  `}
  
  ${media.smm`
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 5px;
  `};
  
  @media (max-height: 740px) and (min-width: 1000px) {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 12px;
  }
`;

const Description = styled.p`
  max-width: 920px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 16px;
  opacity: 0.8;
  color: ${colors.white};

  ${media.tablet`
    font-size: 16px;
    line-height: 21px;
  `}

  ${media.phone`
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 8px;
    padding: 0 4px;
  `};

  ${media.smm`
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 5px;
  `};
  
  @media (max-height: 740px) and (min-width: 1000px) {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 8px;
    padding: 0 4px;
  }
`;

const FileLinkText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${colors.white};
  ${media.phone`
    font-size: 11px;
  `};
`;

const FileLink = styled.a`
  display: inline-block;
  margin: 3px 0;
  margin-left: 18px;
  position: fixed;
  bottom: 29px;
  right: 74px;
  border-bottom: 1px solid transparent;
  transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
  pointer-events: auto;
  z-index: 1;

  ${hoverSupport`
    opacity: 0.5;
  `}

  ${media.phone`
    right: 30px;
    top: 28px;
    bottom: auto;
    padding-right: env(safe-area-inset-right);
  `};
`;

const FileIconWrap = styled.span`
  width: 11px;
  height: 13px;
  left: -18px;
  position: absolute;
  top: 4px;
  bottom: 0;
  margin: auto;
`;

export default MainMask;

import React from 'react';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';

// UTILS
import configureStore from 'redux/store';
// ROUTES

import App from './App';

const { store, history } = configureStore();

// EXPORTED APP
const Client = () => (
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}
    <App history={history} />
    {/* </PersistGate> */}
  </Provider>
);

export default Client;

import { keyframes, css } from 'styled-components/macro';

const fadeInKeyFrame = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
`;

const fadeIn = css`
  animation-name: ${fadeInKeyFrame};
`;

export default {
  fadeIn,
};

import React from 'react';
import PropTypes from 'prop-types';

import { PAGE_TRANSITION } from 'constants/index';
import Animated from '../Animated';

const getDelayPageStart = delayElement => PAGE_TRANSITION + delayElement;

const PageLoad = ({
  className,
  delayIn,
  animationIn,
  animationOut,
  duration,
  children,
  innerRef,
  onClick,
  isVisible,
  outDelayDanger,
  easing,
  animationFillMode,
}) => (
  <Animated
    className={className}
    animateOnMount
    isVisible={isVisible}
    animationIn={animationIn}
    animationOut={animationOut}
    animationFillMode={animationFillMode}
    easingShape={easing}
    innerRef={innerRef}
    duration={{
      in: duration,
      out: outDelayDanger || PAGE_TRANSITION,
    }}
    delay={{
      in: getDelayPageStart(delayIn),
      out: 0,
    }}
    onClick={onClick}
  >
    {children}
  </Animated>
);

/* eslint-disable react/no-unused-prop-types, react/require-default-props, react/forbid-prop-types */

PageLoad.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  animationIn: PropTypes.string,
  animationOut: PropTypes.string,
  delayIn: PropTypes.number,
  duration: PropTypes.number,
  outDelayDanger: PropTypes.number,
  innerRef: PropTypes.func,
  onClick: PropTypes.func,
  isVisible: PropTypes.bool,
  easing: PropTypes.string,
  animationFillMode: PropTypes.string,
};

PageLoad.defaultProps = {
  animationIn: 'fadeIn',
  animationOut: 'fadeOut',
  duration: 400,
  delayIn: 0,
  easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  animationFillMode: null,
};

export default PageLoad;

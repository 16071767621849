export const data1 = [
  {
    id: 'hockeyBest',
    text: 'Хоккей – лучший спорт.',
  },
  {
    id: 'cheer',
    text: 'Болеем за наших.',
  },
  {
    id: 'hockeyCountry',
    text: 'Мы хоккейная страна.',
  },
  {
    id: 'iceIsMelting',
    text: 'Между нами тает лед.',
  },
  {
    id: 'childhoodForHockey',
    text: 'Мы с детства за хоккей.',
  },
  {
    id: 'stickAndPuck',
    text: 'Клюшка и шайба – наши друзья.',
  },
  {
    id: 'hockeyMood',
    text: 'У нас хоккейное настроение.',
  },
  {
    id: 'fabFivGoalkeeper',
    text: 'Великолепная пятерка и вратарь.',
  },
  {
    id: 'cowardHockey',
    text: 'Трус не играет в хоккей.',
  },
  {
    id: 'goalGoal',
    text: 'Нужен гол! Нужен, нужен гол!',
  },
  {
    id: 'hockeyFirst',
    text: 'Хоккей всегда на первом месте.',
  },
  {
    id: 'waitingForVictory',
    text: 'Вся страна победу ждет!',
  },
  {
    id: 'moreWins',
    text: 'Больше побед!',
  },
  {
    id: 'menPlayHockey',
    text: 'В хоккей играют настоящие мужчины.',
  },
  {
    id: 'puckPutterGoalHooray!',
    text: 'Шайба, клюшка, гол – ура!',
  },
  {
    id: 'hockeyGlory',
    text: 'В хоккее наша слава.',
  },
  {
    id: 'waitingForSuccess',
    text: 'На льду нас ждет успех.',
  },
  {
    id: 'victoryWillNotPassBy',
    text: 'Победа мимо не пройдет.',
  },
  {
    id: 'winnerTakeAll',
    text: 'Победителей не судят.',
  },
  {
    id: 'weAreWinners',
    text: 'Мы не знаем горечь поражений.',
  },
  {
    id: 'hockeyTraining',
    text: 'У нас хоккейная закалка.',
  },
  {
    id: 'cheerForOurTeam',
    text: 'Мы болеем за нашу команду.',
  },
  {
    id: 'playNice',
    text: 'Играй красиво! Играй до победы!',
  },
  {
    id: 'hopeHockey',
    text: 'Вера! Надежда! Хоккей!',
  },
  {
    id: 'hockeyDayNight',
    text: 'За хоккей и ночью, и днем.',
  },
  {
    id: 'strongerThanFootball',
    text: 'Мы сильнее футболистов.',
  },
  {
    id: 'playForVictory',
    text: 'Будем играть до победного.',
  },
  {
    id: 'bulletsNotTerrible',
    text: 'Нам не страшны буллиты.',
  },
  {
    id: 'gatesNotMove',
    text: 'Нам не страшны буллиты.',
  },
  {
    id: 'breakingStereotypes',
    text: 'Ломаем клюшки и стереотипы.',
  },
  {
    id: 'beatRecords',
    text: 'Бьём рекорды НХЛ!',
  },
  {
    id: 'motherHockeyPlayer',
    text: 'Лучше, чем хоккеист маминой подруги.',
  },
  {
    id: 'notShaved',
    text: 'Не бреемся, поэтому и не проигрываем.',
  },
  {
    id: 'girlsDream',
    text: 'Как мечта любой девочки.',
  },
  {
    id: 'deserve',
    text: 'Пятерка, которую ты заслуживаешь.',
  },
  {
    id: 'сatReaction',
    text: 'Реакция кошки, скорость мангуста.',
  },
  {
    id: 'сhampionsSoul',
    text: 'Чемпионы твоей души.',
  },
  {
    id: 'bestHockeyPlayers',
    text: 'Хоккеисты – лучшие люди планеты.',
  },
  {
    id: 'lifeLikeIce',
    text: 'Вся жизнь, как лёд – тверда и непоколебима.',
  },
  {
    id: 'hockeyBlood',
    text: 'Хоккей у нас в крови.',
  },
  {
    id: 'lastSeconds',
    text: 'За своих до последней секунды.',
  },
];

export const data2 = [
  {
    id: 'hoorayHoorayHooray',
    text: 'Ура! Ура! Ура-а-а!',
  },
  {
    id: 'hopHey',
    text: 'Хоп-хэй-ла-ла-лэй',
  },
  {
    id: 'goFriends',
    text: 'Оле-ола! Вперед, друзья!',
  },
  {
    id: 'victoryYes',
    text: 'Победа-да-да-да!',
  },
  {
    id: 'chikiBarum',
    text: 'Чики-пи-барум!',
  },
  {
    id: 'comeOn',
    text: 'Оп, давай! Давай!',
  },
  {
    id: 'forHockey',
    text: 'За хоккей! За своих!',
  },
  {
    id: 'kissEveryone',
    text: 'Всем чмоки на этом матче!',
  },
  {
    id: 'washerWasher',
    text: 'Шайбу! Шайбу!',
  },
  {
    id: 'willBeGoal',
    text: 'Вжух! И будет гол!',
  },
  {
    id: 'weNeedVictory',
    text: 'Скибиди па-па-па – победа нам нужна!',
  },
  {
    id: 'timeToScore',
    text: 'Ку-ку! Пора забивать!',
  },
  {
    id: 'hockeyIsCooler',
    text: 'Хоккей круче старых друзей!',
  },
  {
    id: 'playWhileStrength',
    text: 'Давай играть, пока есть силы!',
  },
  {
    id: 'youthAudacity',
    text: 'Молодость и дерзость!',
  },
  {
    id: 'hitPuck',
    text: 'Бей по шайбе!',
  },
  {
    id: 'keepLeggings',
    text: 'Держим краги!',
  },
  {
    id: 'drownIce',
    text: 'Топим лёд – сегодня можно!',
  },
  {
    id: 'defendYours',
    text: 'Защищай своих – атакуй противника!',
  },
  {
    id: 'hockeyFad!',
    text: 'Хоккей – это наш конёк!',
  },
  {
    id: 'washerAgain',
    text: 'Шайбу? Да! Еще раз? Да-да-да!',
  },
];

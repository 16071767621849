import React from 'react';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import styled, { keyframes } from 'styled-components/macro';
import { media } from 'styles';

import icon from '../../assets/images/clockwise.svg';

const Welcome = () => (
  <TouchScrollable>
    <Overlay>
      <Wrapper>
        <Icon />
        <CenterText>
          Пожалуйста, переверните экран в горизонтальное положение
        </CenterText>
      </Wrapper>
      <ScrollLock />
    </Overlay>
  </TouchScrollable>
);

const Wrapper = styled.div`
  font-size: 22px;
  color: #fff;
`;
const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 20px 60px;
  overflow-x: hidden;
  overflow-y: scroll;
  background: rgb(53, 131, 194);
  background: linear-gradient(
    0deg,
    rgba(53, 131, 194, 1) 0%,
    rgba(77, 179, 233, 1) 100%
  );
  z-index: 110;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${media.phone`
   padding: 30px 60px;
  `};
`;

const CenterText = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 20px;
`;

const rotation = keyframes`
  0% { transform:rotate(0deg); }
  20% { transform:rotate(0deg); }
  50% { transform:rotate(90deg); }
  70% { transform:rotate(90deg); }
  100% { transform:rotate(0deg); }
`;

const Icon = styled.div`
  width: 60px;
  height: 75px;
  background-image: url("${icon}");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto;
  margin-bottom: 15px;
  animation: ${rotation} 2s ease-in-out 0s infinite;
`;

export default Welcome;

import React, { Component, Fragment } from 'react';
import styled from 'styled-components/macro';
import { media } from 'styles';
import PropTypes from 'prop-types';
import delay from 'lodash/delay';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectors, actions } from 'redux/ducks/main';
import { withRouter } from 'react-router-dom';

import IconButton from 'components/IconButton';
import SloganButton from 'components/SloganButton';
import firstFrame from 'assets/images/firstFrame.jpg';
import { PageLoad, Animated } from 'components/Animated';
import { PAGE_TRANSITION } from 'constants/index';
import Content from './Content';

import { data1, data2 } from './data';

import Sharing from './Sharing';
import RandomButton from './RandomButton';

import { randomInteger } from './randomInteger';
import CarouselLogos from './CarouselLogos';

const ANIMATION_DURATION = 1900;

class SloganPage extends Component {
  static propTypes = {
    match: PropTypes.shape().isRequired,
    screenSizes: PropTypes.shape().isRequired,
    isVisiblePage: PropTypes.bool.isRequired,
    isFullAnimation: PropTypes.bool.isRequired,
    changeFullAnimation: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { id1 = '', id2 = '' } = props.match.params;

    this.state = {
      isReady: false,
      isRepeat: false,
      isMobileSharing: false,
      firstRandom: id1,
      secondRandom: id2,
    };
  }

  componentDidMount() {
    const { changeFullAnimation, isFullAnimation } = this.props;

    if (isFullAnimation) {
      delay(() => {
        changeFullAnimation(false);
      }, PAGE_TRANSITION);
    }
  }

  handleClick = () => {
    const { isRepeat } = this.state;

    const firstRandom = randomInteger(0, data1.length - 1);
    const secondRandom = randomInteger(0, data2.length - 1);

    const firstId = data1[firstRandom].id;
    const secondId = data2[secondRandom].id;

    this.setState({
      isReady: true,
      isRepeat: !isRepeat,
      firstRandom: firstId,
      secondRandom: secondId,
    });
  };

  handleMobileSharing = () => {
    const { isMobileSharing } = this.state;
    this.setState({
      isMobileSharing: !isMobileSharing,
    });
  };

  render() {
    const {
      isReady,
      isRepeat,
      secondRandom,
      firstRandom,
      isMobileSharing,
    } = this.state;

    const {
      screenSizes: { correctWidth, correctHeight },
      isVisiblePage,
    } = this.props;

    const { protocol, host } = { protocol: 'https:', host: 'kubokgpn.life.ru' };
    const imageName = `${firstRandom}${secondRandom}`;

    const shareUrl = `${protocol}//${host}/slogan/?r=${imageName}`;
    const imageUrl = `${protocol}//${host}/upload/share/${imageName}.jpg`;

    return (
      <Wrapper>
        <BgWrapper>
          <Bg
            correctWidth={correctWidth}
            correctHeight={correctHeight}
            isVisiblePage={isVisiblePage}
          />
        </BgWrapper>
        <PageLoadWrapper isVisible={isVisiblePage}>
          <MainBg />
          <Container>
            <Content />
            {isMobileSharing ? (
              <Mobile>
                {/* Отображение шеринга на мобилке */}
                <SocialWrapper>
                  <Sharing
                    image={imageUrl}
                    url={shareUrl}
                    onClick={this.handleMobileSharing}
                  />
                </SocialWrapper>
              </Mobile>
            ) : (
              <Fragment>
                <List>
                  <ListItem>
                    <RandomButton
                      dataArray={data1}
                      isRepeat={isRepeat}
                      id={firstRandom}
                      animationDuration={ANIMATION_DURATION}
                    />
                  </ListItem>
                  <ListItem>
                    <RandomButton
                      dataArray={data2}
                      isRepeat={isRepeat}
                      id={secondRandom}
                      animationDuration={ANIMATION_DURATION}
                    />
                  </ListItem>
                </List>
                <ButtonWrap>
                  {isReady ? (
                    <div>
                      <Desktop>
                        <IconButton icon onClick={() => this.handleClick()}>
                          Повторить
                        </IconButton>
                      </Desktop>
                      <Mobile>
                        <ButtonContainer>
                          <SloganButton onClick={this.handleMobileSharing}>
                            Поделиться!
                          </SloganButton>
                          <IconButton icon onClick={this.handleClick}>
                            Повторить
                          </IconButton>
                        </ButtonContainer>
                      </Mobile>
                    </div>
                  ) : (
                    <SloganButton onClick={this.handleClick}>
                      {/* Это везде и на мобиле и на декстопе */}
                      Собрать!
                    </SloganButton>
                  )}
                </ButtonWrap>
              </Fragment>
            )}
            <Desktop>
              <CarouselLogos />
              {/* Показ шеринга на декстопе */}
              <SocialWrapper>
                <Animated
                  isVisible={isReady}
                  animationIn="fadeInUpSmall"
                  duration={700}
                  delay={{
                    in: ANIMATION_DURATION,
                    out: 0,
                  }}
                >
                  <Sharing
                    image={imageUrl}
                    url={shareUrl}
                    onClick={() => null}
                  />
                </Animated>
              </SocialWrapper>
            </Desktop>
          </Container>
        </PageLoadWrapper>
      </Wrapper>
    );
  }
}

const PageLoadWrapper = styled(PageLoad)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  text-align: center;
  
  ${media.laptop`
    padding-top: 10px;
  `}
  
  ${media.phone`
    padding: 0 22px;
    padding-top: 25px;
  `}
  
  ${media.sm`
    padding: 0 26px;
    padding-top: 35px;
  `}
`;

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  overflow: hidden;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const BgWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: calc(100vw + 1px);
  height: calc(100vh + 1px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Bg = styled.div`
  background-image: url(${firstFrame});
  background-size: cover;
  flex-shrink: 0;
  width: ${({ correctWidth }) => correctWidth}px;
  height: ${({ correctHeight }) => correctHeight}px;
  transition: filter 0.6s ease;
  filter: ${({ isVisiblePage }) => (isVisiblePage ? 'blur(12px)' : 'none')};
`;

const MainBg = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: calc(100vw + 1px);
  height: calc(100vh + 1px);
  background-color: rgba(0, 0, 0, 0.6);
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 46px;
  margin-bottom: 46px;
  height: 92px;
  
  ${media.laptop`
    margin-top: 35px;
    margin-bottom: 30px;
  `}
  
  ${media.tablet`
    margin-bottom: 15px;
    margin-top: 15px;
  `}
  
  ${media.phone`
    margin-top: 20px;
    margin-bottom: 15px;
    height: 62px;
  `}
  
  ${media.sm`
    margin-top: 5px;
    margin-bottom: 10px;
    height: 42px;
  `}
`;
const ListItem = styled.li`
  position: relative;
  max-width: 326px;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;

  ${media.phone`
    max-width: 250px;
  `}

  ${media.sm`
    max-width: 180px;
    margin-left: 4px;
    margin-right: 4px;
  `};
`;
const ButtonWrap = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  
  ${media.laptop`
    margin-bottom: 30px;
  `}
  
  ${media.tablet`
    margin-bottom: 20px;
  `}
  
  ${media.phone`
    margin-bottom: 10px;
  `}
  
  ${media.sm`
    margin-bottom: 5px;
  `}
`;

const ButtonContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const Desktop = styled.div`
  display: block;

  ${media.phone`
    display: none;
  `};
`;

const Mobile = styled.div`
  display: none;

  ${media.phone`
    display: block;
  `};
`;

const SocialWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: 111px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 28px;
  
  ${media.laptop`
    position: relative;
    bottom: 0;
    min-height: 0;
  `}
  
  ${media.phone`
      min-height: 151px;
  `}
  
  ${media.sm`
      min-height: 99px;
  `}
  
`;

const mapStateToProps = state => ({
  screenSizes: selectors.getScreenSizes(state),
  isFullAnimation: selectors.getFullAnimation(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SloganPage));

import { keyframes, css } from 'styled-components/macro';

const slideInUpKeyFrame = keyframes`
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideInUp = css`
  animation-name: ${slideInUpKeyFrame};
`;

export default {
  slideInUp,
};

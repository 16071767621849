import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { colors, media } from 'styles';

const OvalButton = ({ children }) => <StyledButton>{children}</StyledButton>;

OvalButton.propTypes = {
  children: PropTypes.element.isRequired,
};

const StyledButton = styled.button`
  padding: 0 24px;
  height: 94px;
  border-radius: 14px;
  border: solid 2px ${colors.white};
  font-size: 22px;
  font-weight: 600;
  line-height: 1.36;
  color: ${colors.white};
  background: transparent;
  width: 100%;
  cursor: pointer;
  pointer-events: none;
  overflow: hidden;
  position: relative;
  transition: all 0.2s;
  ${media.phone`
    height: 62px;
    font-size: 14px;
    line-height: 1.29;
    padding: 0 14px;
  `};
  ${media.sm`
    height: 42px;
    font-size: 9px;
    padding: 0 8px;
  `};
`;

export default OvalButton;

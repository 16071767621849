import React from 'react';
import styled from 'styled-components/macro';
import { media } from 'styles';

import logoImg from './logo.svg';

const Logo = () => <StyledSvg src={logoImg} alt="Gazprom logo" />;

const StyledSvg = styled.img`
  width: 291px;
  height: 70px;
  object-fit: contain;

  ${media.phone`
    width: 144px;
    height: 35px;
  `}
`;

export default Logo;

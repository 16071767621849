import pic0 from 'assets/images/pictures/pic0.png';
import pic1 from 'assets/images/pictures/pic1.png';
import pic2 from 'assets/images/pictures/pic2.png';
import pic3 from 'assets/images/pictures/pic3.png';
import pic4 from 'assets/images/pictures/pic4.png';
import pic5 from 'assets/images/pictures/pic5.png';
import pic6 from 'assets/images/pictures/pic6.png';
import pic7 from 'assets/images/pictures/pic7.png';
import pic8 from 'assets/images/pictures/pic8.png';
import pic9 from 'assets/images/pictures/pic9.png';

export const data = [
  {
    id: 'experience',
    pic: pic9,
    width: 90,
    title: 'Опыт',
    description: [
      {
        id: 'experience-key1',
        text:
          'На льду нужно быстро ориентироваться: кому отдать пас, как выбрать позицию для броска по воротам и как действовать в защите своих ворот. Для этого нужен опыт. Чем больше хоккеист тренируется, чем серьезней он подходит к спорту – тем выше его спортивные показатели. Отрабатывать разные приемы на льду, прокручивать в голове движения, придумывать тактику – это нормальная “разминка для ума” хоккеиста, как рассказывает игрок ХК Металлург Павел Дорофеев, который в свои 18 лет уже играет в КХЛ. По словам Павла, в детстве ты учишься наслаждаться игрой, ты познаешь возможности своего тела. И если при этом ты развиваешь свой разум не только в спорте, но и в учебе, культуре, языках, то и реакция на летящую шайбу будет более точной.',
      },
    ],
    comments: [
      {
        id: 'experience-comment-1',
        comment:
          '– Хорошо тренируешься – хорошо играешь. Накапливаешь опыт, отрабатываешь технику, сыгрываешься с командой, развиваешь тело – все это возможно только в тренировочном процессе',
        author:
          'Павел Баулин, тренер детской школы ЦСКА (Москва). В прошлом году команда Павла Баулина стала чемпионом “Кубка Газпром нефти”.',
      },
      {
        id: 'experience-comment-2',
        comment:
          '– Я тренировался и на льду, и в спортзале. И при этом не забывал об учебе. В школе получал нормальные оценки. Сейчас заканчиваю университет. Скорость мысли в хоккее очень важна, а без опыта и разностороннего развития очень сложно выходить на лед',
        author:
          'Павел Дорофеев, игрок КХЛ, нападающий ХК “Металлург” (Магнитогорск)',
      },
    ],
  },
  {
    id: 'support',
    pic: pic0,
    width: 75,
    title: 'Поддержка близких',
    description: [
      {
        id: 'support-key1',
        text:
          'Большинство спортсменов начали свой хоккейный путь с раннего возраста – с 4-6 лет. Родители привели их на лед. Покупали форму и первые коньки. Лечили первые ссадины. Радовались первым победам. Как отмечает спортивный психолог Юлия Родыгина, родители должны поддерживать юного хоккеиста. Детская психика очень подвижна, без помощи взрослых и без правильных слов: “У тебя все получится!” – здесь не справиться.',
      },
      {
        id: 'support-key2',
        text:
          'Воспитать чемпиона – огромный родительский труд. Мама и папа должны рассказать своему спортсмену: чем питаться, во сколько ложиться спать, зачем ходить на тренировки. Не нужно давить на ребенка и требовать от него только побед, как отмечают тренеры клубов. Игра должна приносить удовольствие – это самое главное.',
      },
    ],
    comments: [
      {
        id: 'support-comment-1',
        comment:
          '– Когда ко мне приводят будущего хоккеиста, я дотошно объясняю родителям, что чемпионами не рождаются, а становятся. Если они к этому готовы – мы встаем на коньки',
        author: 'Дмитрий Теплыгин, тренер ХК “СКА” (Санкт-Петербург)',
      },
      {
        id: 'support-comment-2',
        comment:
          '– Я хожу на все игры моего сына, слежу за его тренировками, образом жизни и учебой. Хоккей – это сложная игра, которая требует комплексной подготовки. И я сыну всегда говорю, что он настоящий чемпион, потому что живет спортом. А это приносит результаты',
        author:
          'Сергей Строков, отец юного хоккеиста Алексея Строкова из ХК “Сочи” (Сочи)',
      },
    ],
  },
  {
    id: 'calcium',
    pic: pic1,
    width: 120,
    title: 'Кальций',
    description: [
      {
        id: 'calcium-key1',
        text:
          'Если серьезно заниматься спортом – необходимо заботиться о своем здоровье. По словам тренера Дмитрия Теплыгина, нужно есть как можно больше продуктов, содержащих кальций, чтобы укрепить кости и суставы. Юные хоккеисты не только нагружают свой организм тренировками, но и растут! Поэтому рыба, овощи, орехи, фрукты обязательно должны присутствовать в рационе спортсмена.',
      },
      {
        id: 'calcium-key2',
        text:
          'Рыба и особенно мясо нужны в таком мужском виде спорта как хоккей и для поддержания состояния организма, и для придания сил игрокам. Однако, как отмечают руководители команд, не стоит налегать только на животные продукты. Питание должно быть сбалансированным.',
      },
    ],
    comments: [
      {
        id: 'calcium-comment-2',
        comment:
          '– Никакого фастфуда, никаких газировок и вредных продуктов. Они нарушают баланс всего организма. По спортсмену сразу видно: ел он бургер вчера перед сном, запивая колой – или нет',
        author: 'Павел Баулин, тренер детской школы ЦСКА (Москва)',
      },
    ],
  },
  {
    id: 'healthy',
    pic: pic8,
    width: 85,
    title: 'Здоровая каша',
    description: [
      {
        id: 'healthy-key1',
        text:
          'День спортсмена должен начинаться правильно. Ранний подъем, зарядка и завтрак. Начинать день с бутербродов – это ошибка. Тренеры советуют юным хоккеистам полюбить кашу. Ведь в ней большое количество микроэлементов и сложных углеводов, которые так необходимы для нормальной работы организма и продуктивных тренировок.',
      },
      {
        id: 'healthy-key2',
        text:
          'Каша легко усваивается и не создает ощущения тяжести. В течение длительного времени не появляется чувства голода. Укрепляются кости и суставы. Из организма выводятся шлаки и токсины. А главное – кашу можно приготовить по-разному и угодить любому, даже самому взыскательному спортсмену.',
      },
    ],
    comments: [
      {
        id: 'healthy-comment-1',
        comment:
          '– Каша помогает расшевелить извилины, насытить внутренние органы полезными веществами и придать энергии на весь день',
        author: 'Владимир Иванцов, тренер ХК “Сочи” (Сочи)',
      },
      {
        id: 'healthy-comment-2',
        comment:
          '– Мы надеемся, что игроки вместо чипсов едят здоровую домашнюю еду. Но каша в Финляндии менее популярна, чем запеканка. Макароны с фаршем заливают смесью молока, яиц и тертого сыра. На 40 минут ставят в духовку. Это простое и быстрое блюдо, но очень сытное и питательное. Мне кажется, игроки нашей команды могут есть это блюдо все время и еще добавки попросят',
        author: 'Теему Пусса, тренер ХК “Йокерит” (Финляндия)',
      },
    ],
  },
  {
    id: 'regime',
    pic: pic2,
    width: 82,
    title: 'Режим дня',
    description: [
      {
        id: 'regime-key1',
        text:
          'Юные хоккеисты совмещают учебу в школе с тренировками. Поэтому они просыпаются рано, едят на завтрак кашу, идут на уроки, а потом надевают коньки и выходят на лед. Но нужно не переусердствовать! Тренировки не должны длиться по 6-7 часов. День нужно выстраивать правильно. Игрок КХЛ Павел Дорофеев рассказывает, что в детстве тренировался два часа в спортзале, два часа на льду, затем ехал домой делать уроки и уже в 10-11 вечера ложился спать.',
      },
      {
        id: 'regime-key2',
        text:
          'Завтракать и обедать ребенок должен не позже, чем за полтора часа до тренировки. Иначе могут возникнуть проблемы с пищеварением. После тренировки – обязателен душ, за которым должен последовать отдых и крепкий сон, чтобы организм успел восстановиться.',
      },
    ],
    comments: [
      {
        id: 'regime-comment-1',
        comment:
          '– В Китае нет специализированных спортивных школ для юных хоккеистов, все наши ребята учатся в общеобразовательных школах, поэтому их спортивное расписание подчинено школьному. В Китае хоккей для детей всегда был не спортом, а элементом здорового образа жизни. Постепенно ситуация меняется: в стране уже есть профессиональный клуб «Куньлунь Ред Стар», у него есть команды в МХЛ, ВХЛ и КХЛ, поэтому нынешние дети уже могут задумываться о настоящей карьере в хоккее. А значит, на детском уровне всё должно стать значительно серьезнее и организованнее, чем сейчас',
        author:
          'Андрей Парфёнов, главный тренер молодежной команды «КРС Хейлунцзян»',
      },
    ],
  },
  {
    id: 'sport',
    pic: pic3,
    width: 77,
    title: 'Спорт, кроме хоккея',
    description: [
      {
        id: 'sport-key1',
        text:
          'Умения стоять на коньках и держать клюшку недостаточно для чемпиона. Тело должно быть развито. Мышцы всегда должны быть в тонусе. Поэтому тренировки всегда делятся на лёд и зал. Занятия в спортзале хоккеисты называют “тренировкой на земле”, как рассказывает Павел Дорофеев – нападающий ХК “Металлург”.',
      },
      {
        id: 'sport-key2',
        text:
          'Прыжки через скакалку, упражнения с гантелями, подтягивания, работа на тренажерах – это обязательная программа “земной” тренировки. Владимир Иванцов, тренер ХК “Сочи”, объясняет, что совсем юным спортсменам нельзя сразу давать клюшку, иначе у них нарушится работа лучезапястного сустава. Сначала необходимо укрепить все тело, а затем выходить на лёд и побеждать. ',
      },
    ],
    comments: [
      {
        id: 'sport-comment-1',
        comment:
          '– Мы начинаем наши тренировки с 30-45 минут разминки. Но вместо базовых упражнений: бега или прыжков – мы играем в гандбол и футбол. Эти игры похожи на хоккей быстрой сменой направлений и скоростью принятия решений. С детьми мы сосредоточены на развитии двигательных функций, скорости и ловкости. Тренировку всегда заканчиваем спринтами, чтобы активизировать все мышцы. Выходя на лёд, мы проводим короткий матч, чтобы игроки вдохновились хоккеем. А затем – концентрируемся на катании, обработке шайб, защите и атаке.',
        author: 'Теему Пусса, тренер ХК “Йокерит” (Финляндия)',
      },
    ],
  },
  {
    id: 'intelligence',
    pic: pic4,
    width: 70,
    title: 'Интеллект',
    description: [
      {
        id: 'intelligence-key1',
        text:
          'Стереотип о низком интеллекте спортсмена уже не актуален, как отмечают хоккеисты и тренеры. Молодые люди много читают, интересуются современной культурой и уделяют время своему образованию. Юный хоккеист Алексей Строков из ХК “Сочи” хочет стать профессиональным спортсменом и выступать в КХЛ. Но сейчас он учится в школе и стремится к хорошим оценкам.',
      },
      {
        id: 'intelligence-key2',
        text:
          'Молодых спортсменов в других странах тренеры также заставляют хорошо учиться. Мечта о хоккейной карьере – это прекрасно. Но, как показывает тренерский опыт, хоккеистами становятся далеко не все. А развивать IQ необходимо любому человеку, независимо от его профессии.',
      },
    ],
    comments: [
      {
        id: 'intelligence-comment-1',
        comment:
          '– Я рад, что мой сын занимается хоккеем. Но мы никогда не думали о том, чтоб он стал профессиональным хоккеистом. Гораздо важнее получить хорошее образование, которое потом поможет ему найти свое место в жизни. Для нас школа всегда была важнее, чем хоккей',
        author: 'Лю Ченбан, отец юного хоккеиста из Китая',
      },
    ],
  },
  {
    id: 'sweet',
    pic: pic6,
    width: 106,
    title: 'Сладости',
    description: [
      {
        id: 'sweet-key1',
        text:
          'Лишать юных спортсменов сладкого – это преступление. Детей нужно иногда баловать шоколадом, бананами или мороженым. Как отмечает тренер ХК “СКА” Дмитрий Теплыгин, после физической нагрузки организму нужны быстрые углеводы, чтобы восстановить баланс. Шоколад и бананы – идеальное “быстрое углеводное” лакомство после тренировки.',
      },
      {
        id: 'sweet-key2',
        text:
          'Но необходимо отметить, что шоколад должен быть не молочным, а горьким. Не из пальмового масла, а из настоящих какао-бобов. Не тающий в руках, а растворяющийся во рту с приятным послевкусием. Что касается бананов, они полезные еще и благодаря высокому уровню кальция, который способствует укреплению костей и суставов.',
      },
    ],
    comments: [
      {
        id: 'sweet-comment-1',
        comment:
          '– Мой сын сам для себя решил, что здоровое питание для него важнее баловства с шоколадками. Поэтому после тренировки может съесть банан, о шоколадных батончиках даже не говорит.',
        author:
          'Сергей Строков, отец юного хоккеиста Алексея Строкова из ХК “Сочи” (Сочи)',
      },
    ],
  },
  {
    id: 'card',
    pic: pic7,
    width: 90,
    title: 'Великолепная пятерка и вратарь',
    description: [
      {
        id: 'card-key1',
        text:
          'Командный дух должен присутствовать и на тренировках, и во время игр, и даже когда игроки находятся на расстоянии. Помочь своему сокоманднику, подсказать, поддержать – это нужно уметь делать. Тренеры молодежных хоккейных клубов признаются, что отходят от практики “традиций” в команде. Игроки должны надеяться на себя, свои силы и друг друга, а не на помощь “извне”.',
      },
      {
        id: 'card-key2',
        text:
          'По словам игрока КХЛ Павла Дорофеева, в подростковом возрасте нет ритуалов, которые нужно соблюдать, чтобы не спугнуть фортуну. Но как только у хоккеиста начинает расти борода – бриться перед важными матчами перестают все. Некоторые спортсмены придумывают свои традиции: обойти три раза лёд перед игрой, поспать перед матчем, посидеть в тишине. А потом спортсмены выходят на лёд и показывают, как надо играть в хоккей!',
      },
    ],
    comments: [
      {
        id: 'card-comment-1',
        comment:
          '– Недавно игроки взрослой команды приходили к нам на стадион, встречались с нашими детьми. Зак Юйэнь и Брэндон Ип рассказывали им о себе и о хоккее. И мы увидели, что можно стать и хорошим спортсменом, и образованным человеком, ведь они в свое время учились в колледжах, выступали за студенческие команды, а потом стали профессионалами. Не скажу, будто мы сразу же решили, что наш сын станет профессиональным хоккеистом, но хотя бы у него теперь есть такая перспектива.',
        author: 'Лю Ченбан, отец юного хоккеиста из Китая',
      },
      {
        id: 'card-comment-2',
        comment:
          '– "Нормальные" командные ритуалы: поддержать друг друга перед игрой, вместе провести разминку. Иногда после матча особо отличившийся игрок получает какой-то специальный приз от тренера.',
        author: 'Теему Пусса, тренер ХК “Йокерит” (Финляндия)',
      },
    ],
  },
  {
    id: 'win',
    pic: pic5,
    width: 107,
    title: 'Стремление к победе',
    description: [
      {
        id: 'win-key1',
        text:
          'Царапины, кровь, синяки – это только небольшие препятствия, которые легко преодолеть на пути к кубку, медалям, победе. Настоящие спортсмены не обращают внимание на боль, сложные тренировки и усталость. Для них важна цель. А цель – это победа. Важно научиться правильному отношению к игре. Победа – это всего лишь ступенька для будущих достижений. Поражение – это опыт, благодаря которому можно стать лучше. По словам спортивного психолога Юлии Родыгиной, родителям нужно уметь разговаривать со своими детьми. И объяснять им, что в любом деле важен сам процесс, если научиться получать от него удовольствие – результат тоже будет радовать. Каким бы он ни был.',
      },
    ],
    comments: [
      {
        id: 'win-comment-1',
        comment:
          '– Хоккей – это красиво. Нужно наслаждаться самой игрой. Скоростью, движениями, энергией. Как только спортсмен научится получать кайф от процесса, тренировки превратятся для него в удовольствие, а соревнования – в азарт и драйв. Чемпионство придет со временем, никуда не денется!',
        author: 'Павел Баулин, тренер детской школы ЦСКА (Москва)',
      },
    ],
  },
];

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { timeShape, easingShape } from './propTypes';

import styles from './styles';

class Animated extends PureComponent {
  constructor(props) {
    super(props);
    this.state = props.animateOnMount ? this.getAnimatedState(props) : {};
  }

  componentWillReceiveProps(nextProps) {
    const { isVisible } = this.props;

    if (isVisible !== nextProps.isVisible) {
      this.setState(this.getAnimatedState(nextProps));
    }
  }

  getAnimatedState = props => {
    const {
      isVisible,
      animationIn,
      animationOut,
      duration,
      easing,
      delay,
    } = props;

    const type = isVisible ? 'in' : 'out';

    return {
      animation: isVisible ? animationIn : animationOut,
      delay: delay[type] === 0 || delay[type] ? delay[type] : delay,
      easing: easing[type] ? easing[type] : easing,
      duration:
        duration[type] === 0 || duration[type] ? duration[type] : duration,
    };
  };

  handleClick = () => {
    const { data, onClick } = this.props;

    if (onClick) onClick(data);
  };

  render() {
    const {
      style,
      className,
      children,
      innerRef,
      isVisible,
      animationFillMode,
    } = this.props;

    const { delay, easing, duration, animation } = this.state;

    style.opacity = animation ? null : Number(isVisible);

    return (
      <Root
        className={className}
        animation={animation}
        ref={innerRef}
        style={{
          animationDelay: delay ? `${delay}ms` : null,
          animationTimingFunction: easing,
          animationDuration: duration ? `${duration}ms` : null,
          pointerEvents: isVisible ? 'all' : 'none',
          animationFillMode: animationFillMode || null,
          ...style,
        }}
        onClick={this.handleClick}
      >
        {children}
      </Root>
    );
  }
}

/* eslint-disable react/no-unused-prop-types, react/require-default-props, react/forbid-prop-types */

Animated.propTypes = {
  className: PropTypes.string,
  innerRef: PropTypes.func,
  tag: PropTypes.string,
  style: PropTypes.shape(),
  isVisible: PropTypes.bool,
  animationIn: PropTypes.string,
  animationOut: PropTypes.string,
  animationFillMode: PropTypes.string,
  delay: timeShape,
  duration: timeShape,
  easing: easingShape,
  animateOnMount: PropTypes.bool,
  children: PropTypes.any,
  data: PropTypes.any,
  onClick: PropTypes.func,
};
/* eslint-enable */

Animated.defaultProps = {
  tag: 'div',
  style: {},
  isVisible: true,
  animationIn: 'fadeIn',
  animationOut: 'fadeOut',
  delay: 0,
  duration: 300,
  easing: 'ease',
  animateOnMount: false,
};

const Root = styled.div`
  animation-fill-mode: both;
  ${({ animation }) => styles[animation]}
`;

export default Animated;

import { MainPage, SloganPage } from 'pages/index';

export default [
  {
    component: MainPage,
    exact: true,
    path: '/',
  },
  {
    component: SloganPage,
    // exact: true,
    path: ['/slogan', '/slogan/:id1/:id2'],
  },
];

import React from 'react';
import styled from 'styled-components/macro';
import { colors, media, hoverSupport } from 'styles';
import PropTypes from 'prop-types';

import Vkontakte from 'components/SvgIcons/Social/vk';
import OdnoClassniki from 'components/SvgIcons/Social/ok';
import FaceBook from 'components/SvgIcons/Social/fb';

const getVkShareLink = (url = '') =>
  `https://vk.com/share.php?title=Я поддерживаю детский хоккей и участвую в розыгрыше призов «Кубка Газпром нефти»! %23%0Dкубокгазпромнефти&url=${url}`;

const getOkShareLink = (url = '', image = '') =>
  `https://connect.ok.ru/offer?imageUrl=${image}&url=${url}`;

const getFbShareLink = (url = '') =>
  `https://www.facebook.com/dialog/share?app_id=485036925366577&display=popup&href=${url}&redirect_uri=https://kubokgpn.life.ru`;

const Sharing = ({ url, image, onClick }) => (
  <Wrapper>
    <FlexCont>
      <Text>Поделиться:</Text>
      <List>
        <ListItem>
          <LinkShare target="_blank" href={getOkShareLink(url, image)}>
            <OdnoClassniki />
          </LinkShare>
        </ListItem>
        <ListItem>
          <LinkShare target="_blank" href={getVkShareLink(url, image)}>
            <Vkontakte />
          </LinkShare>
        </ListItem>
        <ListItem>
          <LinkShare target="_blank" href={getFbShareLink(url)}>
            <FaceBook />
          </LinkShare>
        </ListItem>
      </List>
    </FlexCont>
    <HashTag>#кубокгазпромнефти</HashTag>
    <Close onClick={onClick} />
  </Wrapper>
);

Sharing.propTypes = {
  url: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  background: ${colors.white};
  padding: 21px 37px;
  border-radius: 8px;
  position: relative;
  max-height: 111px;

  ${media.phone`
    max-height: 100px;
  `}

  ${media.sm`
    max-height: 90px;
    padding: 15px 25px;
  `}
`;

const FlexCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const List = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListItem = styled.li`
  margin: 0 2px;
  ${media.phone`
    margin: 0;
  `};
`;
const Text = styled.span`
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  color: ${colors.black};
  margin-right: 12px;
  ${media.phone`
    margin-right: 8px;
    font-size: 13px;
  `};
`;
const LinkShare = styled.a`
  display: inline-block;
  text-decoration: none;
  padding: 0 6px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
  
  ${hoverSupport`
    opacity: 0.75;
  `}

  ${media.phone`
    & svg {
      width: 22px;
      height: 22px;
    }
  `};
`;
const HashTag = styled.div`
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.54;
  color: #3179b0;
  margin-top: 10px;
`;

const Close = styled.span`
  display: none;
  width: 35px;
  height: 35px;
  position: absolute;
  right: -44px;
  top: -5px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='30' height='30' viewBox='0 0 30 30'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h16v30H0z'/%3E%3Cpath id='c' d='M14 0h16v30H14z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='matrix(-1 0 0 1 16 0)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath fill='%23FFF' d='M2.073 15L15.748 1.45a.84.84 0 0 0 0-1.2.86.86 0 0 0-1.212 0L.252 14.402a.84.84 0 0 0 0 1.2L14.536 29.75a.866.866 0 0 0 .603.251.84.84 0 0 0 .602-.251.84.84 0 0 0 0-1.201L2.073 14.999z' mask='url(%23b)'/%3E%3C/g%3E%3Cmask id='d' fill='%23fff'%3E%3Cuse xlink:href='%23c'/%3E%3C/mask%3E%3Cpath fill='%23FFF' d='M16.073 15L29.748 1.45a.84.84 0 0 0 0-1.2.86.86 0 0 0-1.212 0L14.252 14.402a.84.84 0 0 0 0 1.2L28.536 29.75a.866.866 0 0 0 .603.251.84.84 0 0 0 .602-.251.84.84 0 0 0 0-1.201L16.073 14.999z' mask='url(%23d)' transform='matrix(1 0 0 -1 0 30)'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-size: 22px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
  
  ${hoverSupport`
    opacity: 0.5;
  `}

  ${media.phone`
    display: block;
  `};
`;
export default Sharing;

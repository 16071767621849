/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { colors, media } from 'styles';

import arrow from 'assets/images/arrow.png';
import SliderArrow from './SliderArrow';

import './style.css';

import { data } from './data';

class SlickSlider extends PureComponent {
  static propTypes = {
    currentId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const { currentId } = props;
    const activeIndex = data.findIndex(({ id }) => id === currentId);

    this.state = {
      nav1: null,
      nav2: null,
      activeSlide: activeIndex,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  handleNextClick = () => {
    this.slider2.slickNext();
  };

  handlePrevClick = () => {
    this.slider2.slickPrev();
  };

  handleChange = (prev, slide) => {
    this.setState(() => ({
      activeSlide: slide,
    }));
  };

  getStyleForImage = id => {
    const { activeSlide } = this.state;

    const activeSlidePlusOne =
      activeSlide === data.length - 1 ? 0 : activeSlide + 1;
    const activeSlidePlusTwo =
      activeSlide === data.length - 1
        ? 1
        : activeSlide === data.length - 2
        ? 0
        : activeSlide + 2;

    const activeSlideMinusOne =
      activeSlide === 0 ? data.length - 1 : activeSlide - 1;
    const activeSlideMinusTwo =
      activeSlide === 0
        ? data.length - 2
        : activeSlide === 1
        ? data.length - 1
        : activeSlide - 2;

    if (activeSlide === id) {
      return {
        transform: 'scale(1)',
        opacity: '1',
      };
    }
    if (id === activeSlidePlusOne || id === activeSlideMinusOne) {
      return {
        opacity: '0.6',
      };
    }
    if (id === activeSlidePlusTwo || id === activeSlideMinusTwo) {
      return {
        opacity: '0.3',
      };
    }

    return {};
  };

  refSlider2 = slider => {
    this.slider2 = slider;
  };

  refSlider1 = slider => {
    this.slider1 = slider;
  };

  getNav = () =>
    data.map((item, index) => (
      <SlideOtline key={item.id}>
        <SlideItem>
          <Image
            src={item.pic}
            alt="pic"
            width={item.width}
            style={this.getStyleForImage(index)}
          />
        </SlideItem>
      </SlideOtline>
    ));

  getSlides = () =>
    data.map(item => (
      <SliderContent key={item.id}>
        <Scrolling>
          <Title>{item.title}</Title>
          {item.description &&
            item.description.map(descr => (
              <Description key={descr.id}>{descr.text}</Description>
            ))}
          {item.comments &&
            item.comments.map(elem => (
              <CommentWrap key={elem.id}>
                <Comment>{elem.comment}</Comment>
                <CommentAuthor>{elem.author}</CommentAuthor>
              </CommentWrap>
            ))}
        </Scrolling>
      </SliderContent>
    ));

  render() {
    const { nav1, nav2 } = this.state;
    const { currentId } = this.props;
    const activeIndex = data.findIndex(({ id }) => id === currentId);

    const nav = this.getNav();
    const slides = this.getSlides();

    return (
      <Wrapper>
        <SliderArrow to="prev" onClick={this.handlePrevClick} />
        <SliderArrow to="next" onClick={this.handleNextClick} />
        <SlideWrap>
          <Slider
            asNavFor={nav1}
            ref={this.refSlider2}
            slidesToShow={5}
            className="center"
            centerMode
            centerPadding="0"
            focusOnSelect
            arrows={false}
            initialSlide={activeIndex}
            beforeChange={this.handleChange}
          >
            {nav}
          </Slider>
        </SlideWrap>
        <Slider
          asNavFor={nav2}
          ref={this.refSlider1}
          fade
          adaptiveHeight
          arrows={false}
          initialSlide={activeIndex}
          swipeToSlide
        >
          {slides}
        </Slider>
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    box-shadow: inset 0px -15px 10px -5px rgba(255, 255, 255, 1);
    border-radius: 15px;
  }
`;

const SlideItem = styled.div`
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SlideWrap = styled.div`
  margin-bottom: 27px;

  ${media.phone`
    margin-bottom: 7px;
  `};
`;

const Title = styled.h3`
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: ${colors.black};
  margin-bottom: 27px;

  ${media.phone`
    font-size: 28px;
    margin-bottom: 8px;
  `};
`;
const Description = styled.p`
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.75;
  text-align: center;
  color: ${colors.black};
  /* opacity: 0.6; */

  ${media.phone`
    font-size: 14px;
    line-height: 1.65;
  `};

  &:last-of-type {
    margin-bottom: 25px;
  }
`;
const SliderContent = styled.div`
  outline: 0;
`;
const SlideOtline = styled.div`
  outline: 0;
`;

const CommentWrap = styled.div`
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  max-width: 510px;
  margin: 0 auto;
  margin-bottom: 17px;
  text-align: center;
  &:last-child {
    margin-bottom: 30px;
  }
`;

const Comment = styled.div`
  display: inline-block;
  border-radius: 22px;
  background-color: #3179b0;
  color: ${colors.white};
  font-size: 14px;
  font-weight: 500;
  min-height: 35px;
  text-align: center;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;
  &:after {
    content: '';
    width: 24px;
    height: 17px;
    background-image: url(${arrow});
    background-size: 23px 15px;
    background-position: bottom center;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    margin: auto;
  }
`;
const CommentAuthor = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: ${colors.black};
  text-align: center;
`;

const Image = styled.img`
  cursor: pointer;

  ${media.phone`
    width: 40%;
  `};

  ${media.sm`
    width: 60%;
  `};
`;

const Scrolling = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 430px);
  min-height: 100px;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  -webkit-overflow-scrolling: touch;
  padding: 0 70px;
  position: relative;
  z-index: 1;

  ${media.laptop`
    max-height: calc(100vh - 380px);
  `};

  ${media.phone`
    padding: 0 27px;
    max-height: calc(100vh - 216px);
  `};
`;

export default SlickSlider;

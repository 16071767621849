import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { selectors } from 'redux/ducks/main';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Logo from 'components/SvgIcons/Logo';

import { colors, media, hoverSupport } from 'styles';

import arrow from '../../assets/images/right-arrow.svg';

const Header = props => {
  const { animationIsEnd, pathname } = props;

  const isSloganPage =
    pathname === '/slogan' || pathname === '/slogan/:id1/:id2';

  const isMainPage = pathname === '/';

  const getLogo = () => {
    if (isMainPage) {
      return <Logo />;
    }
    return (
      <StyledLink to="/">
        <Logo />
      </StyledLink>
    );
  };

  const getBtn = () => {
    if (isSloganPage) {
      return <BackLink to="/">Вернуться на главную</BackLink>;
    }
    return (
      <BlueLink to="/slogan" className={animationIsEnd ? 'active' : ''}>
        <span>Собери кричалку</span>
      </BlueLink>
    );
  };

  return (
    <Container>
      <Wrapper>
        {getLogo()}
        {getBtn()}
      </Wrapper>
    </Container>
  );
};

Header.propTypes = {
  animationIsEnd: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

const Container = styled.div`
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
`;
const Wrapper = styled.div`
  padding: 20px 74px 0 80px;
  width: 100%;
  display: flex;
  align-items: center;

  ${media.phone`
    padding: 21px 20px 0 20px;
  `}
`;

const BackLink = styled(Link)`
  font-size: 18px;
  font-weight: bold;
  margin-left: auto;
  color: ${colors.white};
  position: relative;
  border-bottom: 1px solid transparent;
  transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
  pointer-events: auto;

  ${media.phone`
    display: none;
  `}

  ${hoverSupport`
    opacity: 0.5
    &:after {
      left: -28px;
      ${media.phone`
        left: -22px;
      `}
    }
  `}

  &:after {
    content: '';
    position: absolute;
    width: 17px;
    height: 12px;
    left: -24px;
    top: 0;
    bottom: 0;
    margin: auto;
    background-image: url(${arrow});
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
    ${media.phone`
      background-size: 13px;
      left: -19px;
  `}
  }
`;

const BlueLink = styled(Link)`
  margin-left: auto;
  width: 257px;
  height: 64px;
  border-radius: 48px;
  border: solid 2px #ffffff;
  font-size: 18px;
  font-weight: bold;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
  position: relative;
  pointer-events: auto;

  span {
    z-index: 1;
  }

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(113deg, #00c6fb, #007fc4 73%, #0078bf);
    transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
    z-index: 0;
    opacity: 0;
    border-radius: 40px;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
    z-index: 0;
    opacity: 0;
    border-radius: 40px;
  }

  ${media.phone`
    width: 194px;
    height: 40px;
    font-size: 14px;
  `}

  &.active {
    border: 0;
    transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
    box-shadow: 0 8px 24px 0 rgba(0, 127, 196, 0.7);
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      linear-gradient(109deg, #00c6fb, #007fc4 73%, #0078bf);
    &:after {
      opacity: 1;
    }

    ${hoverSupport`
      box-shadow: 0 8px 24px 0 rgba(0, 127, 196, 0.7);
      &:after {
        opacity: 0;
      }
      &:before {
        opacity: 1;
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.3)
          ),
          linear-gradient(109deg, #00c6fb, #007fc4 73%, #0078bf);
      }
    `}
  }

  ${hoverSupport`
    border-width: 0px;
    box-shadow: 0 8px 24px 0 #005788;
    &:after {
      opacity: 1;
    }
  `}
`;

const StyledLink = styled(Link)`
  position: relative;
  pointer-events: auto;
  transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);

  ${hoverSupport`
    opacity: 0.75;
  `};
`;

const mapStateToProps = state => ({
  animationIsEnd: selectors.getAnimationEnd(state),
});

export default connect(mapStateToProps)(Header);

/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { hoverSupport } from 'styles';


const MainMask = ({ className, onClick }) => (
  <StyledSvg
    className={className}
    version="1.1"
    id="Слой_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 1920 1080`}
    xmlSpace="preserve"
  >
    <path
      onClick={onClick}
      id="intelligence"
      d="M1458.7,842.6c-19.4-85.9-28.3-171.7-47.7-257.6c-19.1,12.8-36.8,25.4-56,38c-4.4,2.9-22,14.6-27.3,13.6
	c-5.4-1-9.4-7.1-13.7-11.6c-6.1-6.4-15-15.6-19.1-23.4c-4.3-8.1-6.4-20.6-5.9-29.6c0.6-10.7,3.7-22.3,4-33
	c0.2-9.1,0.2-28.6,5.7-36.2c-24.2-1.3-51.6,2.9-75.6,6.3c15.8,4.9,25.4,8.4,30.8,10.9c12.8,5.9,4.2,12.6-1,24
	c-10.2,22.5-20.4,42.7-31,65c-4.9,10.3-10.1,21.7-15,32c-5.6,11.8-8.5,13.7-21,9c-3.8-1.4-39.2-14.3-45.2-16.7
	c-0.3-0.1-0.6,0.2-0.5,0.5c3.7,10.5,3.3,22.3,4.9,33.3c1.9,12.8,3.6,25.7,5.4,38.6c3.5,24.7,7,49.4,10.5,74.1
	c3.5,24.7,6.6,49.5,10.5,74.2c2.1,13.6,4.2,12.9,17.4,12.9c11.8,0,23.5,0,35.3-0.2c49.1-0.7,97.9-0.8,146.8-5.7
	c11.7-1.2,23.3-2.5,35-3.4c7.5-0.6,17.8-3.6,25.2-2.8c8.2,0.8,5.1,4.5,14.7,1.8C1455.2,854,1461.1,853.3,1458.7,842.6z"
    />
    <path
      onClick={onClick}
      id="sweet"
      d="M1255.5,521.3c-19.1-7.4-38.7-13.8-57.9-20.7c-12.1-4.3-24.1-8.6-36.2-12.9c-6.9-2.4-26.4-5.6-30.9-11.4
	c-4.4-5.7-1.9-11.1-8.8-16.7c-2-1.6-7-5-9.2-5.2c-3.9-0.3-4.1,2-7.2,2.1c-1.4,0.1-3.8,1.7-5.8,1.9c-2.4,0.2-11.2-4-12.7-4.5
	c-11-3.9-22.1-7.7-33.1-11.6c-14.4-5.1-29.8-11.8-44.3-14.1c-13.8-2.2-19.5-7.1-27.3,7.3c-9.1,16.8-17.8,33.8-26.8,50.7
	c-5,9.5-10,19-15,28.5c-3.2,6.1-13.4,19.3-12.9,26.4c0.6,9,19,16.8,26.4,21.1c13.3,7.6,35.4,13.8,50.7,16.2
	c7.6,1.2,15.2-0.8,22.9,0.1c9.1,1,17.4,6.7,25.5,10.7c5.3,2.6,9,6.6,15,6.8c1.8,0,9-2,10-2c6,0,7.3,4.7,10,5c9.5,1.1,7.4-14.2,18-14
	c5.3,0.1,17.8,10.8,20,15c6.5,12.4-3.1,14.6-8,21c-2.9,3.7,35.2,14.4,40.1,16.2c9.4,3.5,18.8,7,28.2,10.5c11,4.1,12,4.9,17.7-5.2
	c11.3-20.3,20.1-42.3,30.2-63.2C1237.7,572.1,1264.8,524.9,1255.5,521.3z"
    />
    <path
      onClick={onClick}
      id="card"
      d="M951.1,269c0-18.7,0-37.3,0-56c0-8-4.2-36.2,2.2-41.1c4.6-3.5,19.8-1,25.4-1c7.1-0.1,14.3-0.2,21.4-0.2
	c19.1-0.2,38.1-0.2,57.2,0c16.6,0.2,33.3,0.3,49.9,1.1c5.9,0.3,23.2-1.6,27.9,1.5c5.5,3.5,1.5,0.1,4.2,5.6
	c2.9,5.9-0.2,21.1-0.4,27.7c-0.3,9.2-0.7,18.4-1,27.6c-0.6,16-0.9,31.9-1.2,47.9c-0.3,14.3-8.9,18.7-16.7,30
	c-8.5,12.4-14.7,25-17,40c-2.5,16.2-5.9,13.7-23,15c-16.7,1.3-32.1,4.4-48.8,3.9c-17.5-0.5-35.1-3.1-52.7-3.9
	c-10-0.5-23.2,1.1-27.4-7.1C946.8,351.6,954.5,269,951.1,269z"
    />
    <path
      onClick={onClick}
      id="sport"
      d="M845.5,867c6.8-8,9.6-9.2,5.7-19.6c-1.6-4.4-3.8-7.1-6.3-10.9c-4.3-6.5-1.3-5-1.3-11.8c0-7-7.3-13.2-10.3-19.3
	c-2.3-4.7-4.4-9.6-5.8-14.7c-2.2-7.8,0.4-8.8,0.9-16.3c0.7-10.5-8.1-19.7-7-30.6c1-9.6,9.3-20,18.1-23.7
	c20.6-8.7,48.7,8.8,65.5,19.4c9.3,5.9,17.6,12.7,26.5,19.1c6.1,4.4,4.6,4.3,9.8,1.5c5.3-2.8,10.4-11.3,14.5-15.9
	c9-9.9,18.1-19.9,27.1-29.8c3.9-4.3,7.7-8.6,11.6-12.8c5.3-5.6,8-6.3,3.5-12.8c-6.2-9.1-15.3-16.7-21.8-25.8
	c-7.5-10.6-14.2-22-19-34.1c-7.8-19.4-6-43.8,17.5-51.1c11.4-3.5,20.7,1.5,31.7,2.6c5.2,0.5,9.8-1.6,14.9-1.6
	c6.3,0.1,13,2.5,18.9,4.7c8.6,3.3,19.4,12.8,28.5,13.8c4.7,0.5,7.4-3,12.2-1.7c4.4,1.2,4.1,5.9,9.2,3.9c9.6-3.7,6.9-17.8,20.1-11.6
	c8.6,4.1,18.7,12.8,14.9,23c-1.4,3.9-5.9,6-7.8,9.5c-2.2,4-0.6,4-0.7,7.7c0,8.6,2.3,12.3,6.7,20.1c5.3,9.3,11.2,18.8,15.1,28.8
	c4.2,10.6,2.4,21.2,5.4,32.1c5.5,19.8,5.4,43.2-18.7,50.5c-20.3,6.1-45.6-7.7-62.4-17.8c-9.3-5.7-17.9-12.4-27.1-18.3
	c-3.7-2.3-3.8-3.9-8.2-2.4c-4.6,1.6-11.6,12.6-14.6,16c-10,11.1-20.1,22.1-30.2,33.1c-4,4.4-11.8,10-14.3,15.5
	c-2.8,6.1,0.2,7,5.1,12.1c9.1,9.4,17.5,19.4,24.9,30.2c5.9,8.5,17.3,22.5,18.1,33.2c0.6,7-0.6,5.9-8.6,6c-7.4,0.1-14.8,0-22.2,0
	c-15.8,0-31.5,0-47.3,0C907.7,867,876.6,867,845.5,867z"
    />
    <path
      onClick={onClick}
      id="healthy"
      d="M946.3,336.3c3.4-23,0-49-21.7-74.7c-42.4-50.2-121.8-54.3-174.1-18c-24.5,17-42.7,44.9-44.6,75.1
	c-1.1,16.3,2.8,32.6,9.7,47.3c6,12.7,18.1,34.8,30.8,42.3c9.3,5.5,6.5-0.8,10.4-8.3c3-5.7,6.1-8.4,13.1-5.5c2.7,1.1,4.3,3.4,7,4.6
	c2.6,1.1,6.7,0.9,9.5,1.2c6.5,0.9,13.6,2,19.3,5.8c5.3,3.6,8.7,9.8,13.9,13.1c6.4,4,13.3,0.1,20.2,0.1c8.8,0,10.3,5,17.3,7.6
	c7.9,2.9,13-4.8,20-7.6c7.7-3.1,9.8-5.2,16.8-9.8c5.8-3.8,12.3-7.8,17.3-11.8C917.8,392.6,942.6,361.7,946.3,336.3z"
    />
    <path
      onClick={onClick}
      id="regime"
      d="M916.9,491c16.6,2.8,22.4-19.6,21.3-33c-0.7-7.9-8-16.5-7.3-23.9c0.7-6.9,7.1-17.1-6.4-15.5
	c-4.6,0.5-4.5,3.2-9.3,1.6c-1.9-0.6-5.2-3.2-8.9-3c-7.1,0.3-14.3,0.1-21.3,0.8c-9.1,1-15.1,9.4-23.3,10.5c-7.2,1-10.6-6.8-17.8-8.1
	c-6-1.1-13,2.5-18.9,1.6c-8-1.1-11.1-8.3-17-13c-6.5-5.2-10.8-6.5-18.7-7.5c-6.1-0.7-13.5-1-18.3-5.5c-2.5-2.4-7.7-4.6-12,2
	c-2.9,4.4-3.6,10.1-7,15c-5.1,7.4-11,12.7-13,22c-1.4,6.4,1.3,14-0.5,20c-2.2,7.2-11.1,14.4-15,21c-8.7,14.7-13.4,31.4-13,48.5
	c0.2,7.7,1.6,14.8,3,22.3c1.6,9.2,0.9,18,1.5,27.3c1.2,19.1,7.9,34.9,16.4,51.7c2.4,4.8,3.5,4.8,0.3,9.1c-2.5,3.3-7.2,2-7.5,7.6
	c-0.2,3.4,2.7,6.9,6.1,7.1c7.3,0.5,4.7-7,7.7-9.1c5.4-3.7,11.5,6.4,16.4,10c13.2,9.9,29.3,16.7,45.4,19.8c8.7,1.6,17.5,2.2,26.3,1.8
	c9.3-0.4,18.3-3.9,27.4-4.3c9.9-0.5,10.4,5,17.5-3c4.9-5.6,10.5-8.4,17.2-13.1c15.6-11,28.4-27,35.5-44.7
	c6.7-16.8,7.9-35.1,5.3-52.8C926,533.2,922.4,511.2,916.9,491z"
    />
    <path
      onClick={onClick}
      id="win"
      d="M1467.7,441.7c-5.6,4.4-16.3-14.8-19.4-18c-10.8-11.3-7.2-10.9-21-3.5c-21.1,11.4-45,37.9-68.7,42
	c-6.4,1.1-16.4-1.5-22.6-3.6c-12.1-4.1-9.6-4.5-13.3-13.1c-8.8-20.7-0.8-27.2,0.8-48.9c10.8,7.1,15.4,2.9,13.6-11
	c-0.6-4.7-5.9-5.7-5.8-10.2c0.5-22.9,13.2-44.2,11.5-68.6c-1.5-21.5-12.2-45.5-31.1-57.3c-6.9-4.3-16.8-8.6-25.1-6.7
	c-12.6,3-10.9,13.6-19.2,19.3c-14.1,9.7-29-16.8-47.6-17.1c-23.2-0.4-46.6,15.3-63.6,29.5c-16.4,13.6-31.4,30.7-41.3,49.6
	c-5.1,9.8-7.3,19-10.5,29.4c-5.6,18.2-6.3,11-23.7,16.8c-17.7,5.8-20.1,22.8-14,39.9c7.9,21.9,34.2,34.5,55.1,40.9
	c23.4,7.2,47.5,6.5,71.7,6.3c23.3-0.1,42.1,10.7,66,6.5c17.3-3,27.7-7.3,44.6,1c7.3,3.6,17.9,14.3,12.5,26
	c-2.7,5.7-13,6.4-16.5,11.3c-5.8,8-4.3,18.2-4.8,28.3c-1.3,25.4-11,61,8,79.6c5.5,5.4,14.6,18,19.4,21.4c10.1,7.3,16.1-0.5,27.8-8.3
	c27.5-18.2,54.9-36.4,82.5-54.5c10.1-6.6,25.4-12.9,30.9-24.3c5.2-10.8,3-28.2,4.3-40C1468.8,498.2,1475.8,435.4,1467.7,441.7z
	 M1078.9,403.7c-12.7-12.6,27.2-21.1,33.9-19.4c3.4,0.8,55.9,48.3,55.9,50.9c-10.2-5.1-30.7-1.1-43-4.6
	C1108.1,425.7,1092,416.7,1078.9,403.7z M1311.6,341.8c-5-11.3-11.1-22.1-18.1-32.3c-3.6-5.2-15.2-16.1-17-21.9
	c-4.9-15,6.6-26.2,23-21.6c16.2,4.6,29.1,25.7,33.1,40.6c6,22.5-7,41.5-10.4,63C1318.2,360.5,1315.6,350.9,1311.6,341.8z"
    />
    <path
      onClick={onClick}
      id="experience"
      d="M470.1,489.8c-24.8-10.5-41.9-21.1-31.5-49.7c10.5-28.8,21.7-24.3,13.8-53.9c-7.6-28.4,2.3-48.3,15-73.8
	c14.7-29.4,21.9-53.5,25.5-85.7c1.5-13.3,3.2-68.4,15.4-74c26.3-12.1,34.5,32.2,38.8,46.3c10.7-28.2,58.7-53.5,77.9-27.2
	c5,6.8-4.4,53.4-5.8,66c-3.2,29.4-5.5,69.2,4.4,86.1c16.3,27.7,36.5,40.2,64.1,62.3c13.4,10.7,25,20,36.8,32.4
	c4.2,4.4,7.4,8.1,9.3,15.6c2.9,11.2-10.9,36.3-13.6,40.8c-10.7,17.9-13.9,38.6-11.8,59.3c2.5,24.5,12.1,26.1-15,21.8
	C692.9,555.9,536.7,517.9,470.1,489.8z"
    />
    <path
      onClick={onClick}
      id="slogan"
      d="M1823.54,763.47c-46.75-10.99-93.41-22.37-140.12-33.5c-21.91-5.22-43.81-10.46-65.72-15.66
c-23.69-5.62-37.57-4.1-36.83-31.3c0.55-20.13,5.86-39.27,9.92-58.86c4.45-21.49,4.81-52.76,14.64-72.03
c6.67-13.07,17.7-10.54,23.43-25.09c4.69-11.9,1.59-40.98,15.09-43.85c13.72-2.92,43.05,11.55,57.44,14.91
c7.73,1.81,17.13,5.51,25.2,5.72c5.41,0.14,11.71-4.31,17.01-3.11c8.57,1.94,16.57,10.28,25.69,13.12
c9.53,2.98,19.82,4.52,29.58,6.74c11.05,2.52,43.8,4.9,49.01,14.55c7.17,13.29-13.07,39.88-6.5,55.23
c4.98,11.65,9.65,4.85,11.38,17.34c1.14,8.29-4.37,25.32-5.79,33.61C1844.83,653.61,1829.49,764.18,1823.54,763.47z"
    />
    <path
      onClick={onClick}
      id="support"
      d="M693.8,559.5c9.5,20,2.6,31.7-12.1,44.9c-31.3,28.3-65.6,53.9-98.3,80.5c-34.8,28.3-69.6,56.6-104.5,84.7
	c-8.5,6.8-39.6,39.5-49.6,36c-8.3-2.9-15.9-29.5-15-36.3c1.3-10.6,11.8-28,14.5-40.1c5.2-22.7,10.4-45.4,15.6-68.1
	c5.2-22.6,11.7-45.3,15.6-68.1c2.8-16,0.7-32.1,13.8-44.1c20.9-19.2,61.6-4.4,86.9-2.6C575.8,547.5,688.5,548.5,693.8,559.5z"
    />
    <path
      onClick={onClick}
      id="calcium"
      d="M749,832.9c-56.5-2-136,9-171.8-47.4c-30.1-47.4,2.8-102.9,53.3-113.6c45.3-9.6,159.2,15.6,165.9,75.1
	c1.3,11.9-3.8,14.4-7.1,24.5c-8,24.8,0.9,2.4-1.5,18.7C782.7,824.2,785.5,834.1,749,832.9C738.1,832.5,757.3,833.2,749,832.9z"
    />
  </StyledSvg>
);

const StyledSvg = styled.svg`
  cursor: auto;

  path {
    fill: transparent;
    cursor: pointer;
    stroke: transparent;
    stroke-width: 10px;
    transition: stroke 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);

    ${hoverSupport`
      stroke: white;
    `}
  }
`;

MainMask.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  correctWidth: PropTypes.number.isRequired,
  correctHeight: PropTypes.number.isRequired,
};

MainMask.defaultProps = {
  className: '',
};

export default MainMask;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { media, hoverSupport } from 'styles';

class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    handleCloseClick: PropTypes.func.isRequired,
  };

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  closeModal = () => {
    const { handleCloseClick } = this.props;
    this.scrollTop();
    handleCloseClick();
  };

  render() {
    const { children } = this.props;

    return (
      <Popup>
        <Container>
          <Wrapper>
            <Close onClick={this.closeModal} />
            {children}
          </Wrapper>
        </Container>
      </Popup>
    );
  }
}

const Container = styled.div`
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
`;

const Wrapper = styled.div`
  padding: 47px 0;
  padding-bottom: 0;
  max-width: 860px;
  margin: 0 auto;
  border-radius: 14px;
  background: #fff;
  position: relative;

  ${media.phone`
    padding-top: 10px;
  `}
`;
const Popup = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 20px 60px;
  padding-top: 150px;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);
  z-index: 100;
  box-sizing: border-box;

  ${media.laptop`
  padding-top: 115px;
  `}

  ${media.phone`
     padding: 30px 60px;
     padding-top:70px;
  `};
`;
const Close = styled.span`
  display: block;
  width: 45px;
  height: 45px;
  position: absolute;
  right: -5px;
  top: -50px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='30' height='30' viewBox='0 0 30 30'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h16v30H0z'/%3E%3Cpath id='c' d='M14 0h16v30H14z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='matrix(-1 0 0 1 16 0)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath fill='%23FFF' d='M2.073 15L15.748 1.45a.84.84 0 0 0 0-1.2.86.86 0 0 0-1.212 0L.252 14.402a.84.84 0 0 0 0 1.2L14.536 29.75a.866.866 0 0 0 .603.251.84.84 0 0 0 .602-.251.84.84 0 0 0 0-1.201L2.073 14.999z' mask='url(%23b)'/%3E%3C/g%3E%3Cmask id='d' fill='%23fff'%3E%3Cuse xlink:href='%23c'/%3E%3C/mask%3E%3Cpath fill='%23FFF' d='M16.073 15L29.748 1.45a.84.84 0 0 0 0-1.2.86.86 0 0 0-1.212 0L14.252 14.402a.84.84 0 0 0 0 1.2L28.536 29.75a.866.866 0 0 0 .603.251.84.84 0 0 0 .602-.251.84.84 0 0 0 0-1.201L16.073 14.999z' mask='url(%23d)' transform='matrix(1 0 0 -1 0 30)'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
  
  ${hoverSupport`
    opacity: 0.75;
  `}

  ${media.laptop`
    right: 0;
    left: 0;
    margin: auto;
    top: -50px;
  `}
`;

export default Modal;

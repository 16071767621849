import React from 'react';
import styled from 'styled-components/macro';

import Header from 'components/Header';
import Footer from 'components/Footer';

const Common = ({ pathname }) => (
  <div>
    <Wrapper>
      <Header pathname={pathname} />
      <Footer />
    </Wrapper>
  </div>
);

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100vw;
  pointer-events: none;
`;

export default Common;

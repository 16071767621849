import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import styled, { keyframes } from 'styled-components/macro';

import OvalButton from 'components/OvalButton';

class RandomButton extends Component {
  static propTypes = {
    dataArray: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isRepeat: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    animationDuration: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      anim: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { isRepeat } = this.props;
    if (nextProps.isRepeat !== isRepeat) {
      this.initAnimation();
    }
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  initAnimation = () => {
    const { animationDuration } = this.props;

    this.setState({ anim: true });
    this.timeout = setTimeout(() => {
      this.setState({ anim: false });
    }, animationDuration);
  };

  render() {
    const { dataArray, id } = this.props;
    const { anim } = this.state;

    const items = dataArray.map(item => (
      <Fragment key={item.id}>
        <AnimItem>{item.text}</AnimItem>
      </Fragment>
    ));

    const currentItemIndex = dataArray.findIndex(item => item.id === id);

    const currentItem =
      currentItemIndex !== -1
        ? dataArray[currentItemIndex].text
        : dataArray[0].text;

    return (
      <OvalButton>
        {anim ? <Anim>{items}</Anim> : <div>{currentItem}</div>}
      </OvalButton>
    );
  }
}

const slotMachine = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -100%);
  }
`;

const Anim = styled.div`
  position: absolute;
  max-height: 900px;
  left: 0;
  right: 0;
  padding: 0 14px;
  margin: auto;
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  animation: ${slotMachine} 0.2s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  opacity: 0.4;
  animation-delay: ${props => props.delay};
`;

const AnimItem = styled.div`
  padding: 5px 0;
`;

export default RandomButton;

import React, { PureComponent } from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions, selectors } from 'redux/ducks/main';

import Sq from 'components/Sq';
import images from 'assets/images/newSprite.jpg';
import MainMask from 'components/MainMask';
import Modal from 'components/Modal';
import SlickSlider from 'components/SlickSlider';
import { media } from 'styles';

import { PageLoad, Animated } from 'components/Animated';
import Content from './Content';

const FRAME_COUNT = 18; // этот параметр должен быть равен кол-во кадров в секвенции

const SPEED_CHANGE_SLIDE = 55;
const BACK_SPEED_CHANGE_SLIDE = 40;

/* 40 - Получается путем
нахождения деления одной секунды 1000 миллисекунд
на 25 кадров
(примерное количество который человеческий глаз не успевает заметить разрывность видео)
Если константа не равна 40, значит понадобилось изменить скорость
*/

class MainPage extends PureComponent {
  static propTypes = {
    history: PropTypes.shape().isRequired,
    animationIsEnd: PropTypes.bool.isRequired,
    changeAnimationIsEnd: PropTypes.func.isRequired,
    screenSizes: PropTypes.shape().isRequired,
    isFullAnimation: PropTypes.bool.isRequired,
    isVisiblePage: PropTypes.bool.isRequired,
    changeFullAnimation: PropTypes.func.isRequired,
    transitionStatus: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isShowPopup: false,
      currentId: '',
      sqImage: '',
      backAnimation: false,
    };

    props.changeAnimationIsEnd(false);
  }

  componentDidMount = () => {
    const SequenseImages = new Image();
    SequenseImages.onload = () => {
      this.setState(() => ({
        sqImage: images,
      }));
    };

    SequenseImages.src = images;
  };

  handleBtnClick = () => {
    const { changeFullAnimation } = this.props;
    changeFullAnimation(false);
  };

  handleEndSq = () => {
    const { changeAnimationIsEnd } = this.props;

    changeAnimationIsEnd(true);
  };

  handleStartNewSq = () => {
    const { changeAnimationIsEnd } = this.props;

    changeAnimationIsEnd(false);
  };

  goToSloganPage = () => {
    const { history } = this.props;

    history.push('/slogan');
  };

  handleMaskClick = evt => {
    const { id } = evt.target;

    if (id === 'slogan') {
      this.goToSloganPage();
    } else {
      this.setState(() => ({
        isShowPopup: true,
        currentId: id,
      }));
    }
  };

  handleCloseClick = () => {
    this.setState(() => ({
      isShowPopup: false,
    }));
  };

  render() {
    const { isShowPopup, currentId, sqImage, backAnimation } = this.state;

    const {
      animationIsEnd,
      screenSizes: {
        windowWidth,
        windowHeight,
        correctWidth,
        correctHeight,
        isAlighCenterForWidth,
      },
      isFullAnimation,
      transitionStatus,
      isVisiblePage,
    } = this.props;

    const differentWidth = (correctWidth - windowWidth) / 2;

    return (
      <Wrapper>
        {isFullAnimation && (
          <ContentWrapper isVisible={isVisiblePage}>
            <Content handleBtnClick={this.handleBtnClick} />
          </ContentWrapper>
        )}
        <StyledSq
          show={
            backAnimation
              ? false
              : !isFullAnimation && transitionStatus === 'entered'
          }
          frameCount={FRAME_COUNT}
          speed={SPEED_CHANGE_SLIDE}
          backSpeed={BACK_SPEED_CHANGE_SLIDE}
          images={sqImage}
          end={this.handleEndSq}
          start={this.handleStartNewSq}
          isEndAnimation={animationIsEnd}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
          correctWidth={correctWidth}
          correctHeight={correctHeight}
          isAlighCenterForWidth={isAlighCenterForWidth}
          differentWidth={differentWidth}
        />
        <MaskWrapper>
          <StyledMainMask
            onClick={this.handleMaskClick}
            correctWidth={correctWidth}
            correctHeight={correctHeight}
            animationIsEnd={animationIsEnd}
          />
        </MaskWrapper>
        {isShowPopup && (
          <AnimatedModal
            isVisible={isShowPopup}
            animationIn="fadeInUpSmall"
            duration={700}
            animateOnMount
          >
            <Modal handleCloseClick={this.handleCloseClick}>
              <SlickSlider currentId={currentId} />
            </Modal>
          </AnimatedModal>
        )}
      </Wrapper>
    );
  }
}

const AnimatedModal = styled(Animated)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100vw;
  box-sizing: border-box;

  &::after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      -180deg,
      rgba(0, 0, 0, 0.48) 0%,
      rgba(0, 0, 0, 0) 25%
    );
    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: linear-gradient(
      -180deg,
      rgba(255, 255, 255, 0) 78%,
      rgba(0, 0, 0, 0.56) 100%
    );
    pointer-events: none;
  }
`;

const ContentWrapper = styled(PageLoad)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 1;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  ${media.phone`
     padding-top: 12vh;
  `}
`;

const StyledSq = styled(Sq)`
  position: fixed;
  width: 1920px;
  height: 100vh;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  backface-visibility: hidden;
`;

const MaskWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledMainMask = styled(MainMask)`
  flex-shrink: 0;
  width: ${({ correctWidth }) => correctWidth}px;
  height: ${({ correctHeight }) => correctHeight}px;
  visibility: ${({ animationIsEnd }) =>
    animationIsEnd ? 'visible' : 'hidden'};
`;

const mapStateToProps = state => ({
  animationIsEnd: selectors.getAnimationEnd(state),
  screenSizes: selectors.getScreenSizes(state),
  isFullAnimation: selectors.getFullAnimation(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MainPage));

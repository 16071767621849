import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import GazProm from 'components/SvgIcons/GazProm';

import { colors, media, hoverSupport } from 'styles';

const IconButton = ({ children, onClick }) => (
  <StyledButton onClick={onClick}>
    <ButtonIcon>
      <GazProm />
    </ButtonIcon>
    <ButtonText>{children}</ButtonText>
  </StyledButton>
);

IconButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const StyledButton = styled.button`
  border: 0;
  font-size: 26px;
  font-weight: bold;
  color: ${colors.white};
  height: 80px;
  border-radius: 40px;
  box-shadow: 0 8px 24px 0 rgba(0, 127, 196, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 240px;
  transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
  position: relative;
  overflow: hidden;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    linear-gradient(109deg, #00c6fb, #007fc4 73%, #0078bf);
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(113deg, #00c6fb, #007fc4 73%, #0078bf);
    transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
    z-index: 0;
    border-radius: 40px;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s cubic-bezier(0.41, 0.01, 0.27, 1);
    z-index: 0;
    opacity: 0;
    border-radius: 40px;
  }
  ${hoverSupport`
    box-shadow: 0 8px 24px 0 #005788;
    background-image: linear-gradient(113deg, #00c6fb, #007fc4 73%, #0078bf);
    ${media.phone`
      & svg {
        width: 18px;
      & g path {
        fill: #fff;
        }
      }
    `}
    &:after {
      opacity: 0;
    }
    &:before {
      opacity: 1;
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.3),
          rgba(0, 0, 0, 0.3)
        ),
        linear-gradient(109deg, #00c6fb, #007fc4 73%, #0078bf);
    }
  `}

  ${media.phone`
    position: absolute;
    right: -78px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    box-shadow: 0 8px 24px 0 rgba(0, 127, 196, 0.3);
    background-color: #ffffff;
    background-image: none;
    & svg{
      width: 18px;
      height: 18px;
      & g path{
        transition: all 0.1s cubic-bezier(0.41, 0.01, 0.27, 1);
        fill: #005788;
      }
    }
    &:after{
      background-image: none;
    }
    &:hover{
      &:before{
        background-image: linear-gradient(113deg, #00c6fb, #007fc4 73%, #0078bf);
      }
    }
    
  `}

  ${media.sm`
    width: 42px;
    height: 42px;
    border-radius: 42px;
    padding: 0;
  `}
`;
const ButtonText = styled.span`
  margin-left: 7px;
  display: inline-block;
  position: relative;
  z-index: 1;
  ${media.phone`
    display: none;
  `}
`;
const ButtonIcon = styled.span`
  display: flex;
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 1;
  ${media.phone`
    width: 18px;
    height: 18px;
  `}
`;

export default IconButton;

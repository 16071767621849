import pic1 from './img/1blackwings.png';
import pic2 from './img/2dinamo_m.png';
import pic3 from './img/3dinamo_mn.png';
import pic4 from './img/4dinamo_r.png';
import pic5 from './img/5jokerit.png';
import pic6 from './img/6lada.png';
import pic7 from './img/7lokomotiv.png';
import pic8 from './img/8ocelari.png';
import pic9 from './img/9vityaz.png';
import pic10 from './img/10ska.png';
import pic11 from './img/11severstal.png';
import pic12 from './img/12yamal.png';
import pic13 from './img/13slovan.png';
import pic14 from './img/14sochi.png';
import pic15 from './img/15spartak.png';
import pic16 from './img/16cska.png';
import pic17 from './img/17kec.png';
import pic18 from './img/18avangard.png';
import pic19 from './img/19avtomobilist.png';
import pic20 from './img/20admiral.png';
import pic21 from './img/21ak_bars.png';
import pic22 from './img/22barys.png';
import pic23 from './img/23kunlun_red_star.png';
import pic24 from './img/24metallurg_mg.png';
import pic25 from './img/25neftekhimik.png';
import pic26 from './img/26rubin.png';
import pic27 from './img/27salavat_ulaev.png';
import pic28 from './img/28sibir.png';
import pic29 from './img/29torpedo.png';
import pic30 from './img/30traktor.png';
import pic31 from './img/31ugra.png';

export const data = [
  {
    id: 'key-blackwings',
    title: 'blackwings',
    img: pic1,
  },
  {
    id: 'key-dinamo_m',
    title: 'dinamo_m',
    img: pic2,
  },
  {
    id: 'key-dinamo_mn',
    title: 'dinamo_mn',
    img: pic3,
  },
  {
    id: 'key-dinamo_r',
    title: 'dinamo_r',
    img: pic4,
  },
  {
    id: 'key-jokerit',
    title: 'jokerit',
    img: pic5,
  },
  {
    id: 'key-lada',
    title: 'lada',
    img: pic6,
  },
  {
    id: 'key-lokomotiv',
    title: 'lokomotiv',
    img: pic7,
  },
  {
    id: 'key-ocelari',
    title: 'ocelari',
    img: pic8,
  },
  {
    id: 'key-vityaz',
    title: 'vityaz',
    img: pic9,
  },
  {
    id: 'key-ska',
    title: 'ska',
    img: pic10,
  },
  {
    id: 'key-severstal',
    title: 'severstal',
    img: pic11,
  },
  {
    id: 'key-yamal',
    title: 'yamal',
    img: pic12,
  },
  {
    id: 'key-slovan',
    title: 'slovan',
    img: pic13,
  },
  {
    id: 'key-sochi',
    title: 'sochi',
    img: pic14,
  },
  {
    id: 'key-spartak',
    title: 'spartak',
    img: pic15,
  },
  {
    id: 'key-cska',
    title: 'cska',
    img: pic16,
  },
  {
    id: 'key-kec',
    title: 'kec',
    img: pic17,
  },
  {
    id: 'key-avangard',
    title: 'avangard',
    img: pic18,
  },
  {
    id: 'key-avtomobilist',
    title: 'avtomobilist',
    img: pic19,
  },
  {
    id: 'key-admiral',
    title: 'admiral',
    img: pic20,
  },
  {
    id: 'key-ak_bars',
    title: 'ak_bars',
    img: pic21,
  },
  {
    id: 'key-barys',
    title: 'barys',
    img: pic22,
  },
  {
    id: 'key-kunlun_red_star',
    title: 'kunlun_red_star',
    img: pic23,
  },
  {
    id: 'key-metallurg_mg',
    title: 'metallurg_mg',
    img: pic24,
  },
  {
    id: 'key-neftekhimik',
    title: 'neftekhimik',
    img: pic25,
  },
  {
    id: 'key-rubin',
    title: 'rubin',
    img: pic26,
  },
  {
    id: 'key-salavat_ulaev',
    title: 'salavat_ulaev',
    img: pic27,
  },
  {
    id: 'key-sibir',
    title: 'sibir',
    img: pic28,
  },
  {
    id: 'key-torpedo',
    title: 'torpedo',
    img: pic29,
  },
  {
    id: 'key-traktor',
    title: 'traktor',
    img: pic30,
  },
  {
    id: 'key-ugra',
    title: 'ugra',
    img: pic31,
  },
];

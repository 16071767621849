/* eslint-disable */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import firstFrame from 'assets/images/firstFrame.jpg';

const DIVERGENCE = 10;

class Sq extends PureComponent {
  constructor(props) {
    super(props);
    this.inProgress = false;
    this.state = {
      percentage: 0,
    };
  }

  componentDidMount = () => {
    const { speed, show } = this.props;

    if (show && !this.inProgress) {
      this.inProgress = true;
      this.interval = setInterval(this.changePercentage, speed);
    }
  };

  componentDidUpdate = prevProps => {
    const {
      speed,
      show,
      correctWidth,
      frameCount,
      backSpeed,
      isEndAnimation,
    } = this.props;

    if (show && !this.inProgress) {
      this.inProgress = true;
      this.interval = setInterval(this.changePercentage, speed);
    }

    if (isEndAnimation && correctWidth !== prevProps.correctWidth) {
      const maxAmountFrames = correctWidth * frameCount;
      const finishBreakPoint = maxAmountFrames - correctWidth;

      this.setState(() => ({
        percentage: finishBreakPoint,
      }));
    }

    if (!show && prevProps.show) {
      this.inProgress = true;
      this.interval = setInterval(this.changePercentageBack, backSpeed);
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  addPercentage = () => {
    const { correctWidth } = this.props;

    this.setState(prevState => ({
      percentage: prevState.percentage + correctWidth,
    }));
  };

  removePercentage = () => {
    const { correctWidth } = this.props;

    this.setState(prevState => ({
      percentage: prevState.percentage - correctWidth,
    }));
  };

  changePercentage = () => {
    const { correctWidth, frameCount, end, finishesVideo } = this.props;

    const maxAmountFrames = correctWidth * frameCount;
    const { percentage } = this.state;

    const finishBreakPoint = maxAmountFrames - (correctWidth + DIVERGENCE);

    if (percentage >= finishBreakPoint) {
      end();
      this.inProgress = false;
      clearInterval(this.interval);
    } else {
      this.addPercentage();
    }

    if (finishesVideo && percentage >= finishBreakPoint / 2) {
      finishesVideo();
    }
  };

  changePercentageBack = () => {
    const { end, finishesVideo, start } = this.props;
    const { percentage } = this.state;

    const finishBreakPoint = DIVERGENCE;
    start();

    if (percentage <= finishBreakPoint) {
      end();
      this.inProgress = false;
      clearInterval(this.interval);
    } else {
      this.removePercentage();
    }

    if (finishesVideo && percentage >= finishBreakPoint / 2) {
      finishesVideo();
    }
  };

  render() {
    const {
      className,
      images,
      frameCount,
      correctWidth,
      correctHeight,
      isAlighCenterForWidth,
      differentWidth,
    } = this.props;

    const { percentage } = this.state;

    let backgroundPosition;

    if (isAlighCenterForWidth) {
      backgroundPosition = `-${percentage + differentWidth}px center`;
    } else {
      backgroundPosition = `-${percentage}px center`;
    }

    const maxAmountFrames = correctWidth * frameCount;

    const styleImage = {
      backgroundSize: `${maxAmountFrames}px ${correctHeight}px`,
      backgroundPosition,
      backgroundImage: `url(${images})`,
      backgroundRepeat: 'no-repeat',
      width: `100vw`,
      height: `100vh`,
    };

    const firstImage = {
      backgroundSize: `${correctWidth}px ${correctHeight}px`,
      backgroundPosition,
      backgroundImage: `url(${firstFrame})`,
      width: `100vw`,
      height: `100vh`,
    };

    return (
      <Fragment>
        <div className={className} style={firstImage} />
        <div className={className} style={styleImage} />
      </Fragment>
    );
  }
}

Sq.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  correctWidth: PropTypes.number,
  correctHeight: PropTypes.number,
  frameCount: PropTypes.number,
  speed: PropTypes.number,
  images: PropTypes.string,
  end: PropTypes.func,
  finishesVideo: PropTypes.func,
};

export default Sq;
